import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Layout from "scenes/layout";
import Login from "scenes/login";
import Registro from "scenes/register";
import ResetPass from "scenes/resetPass";
import CreatePass from "scenes/createPass";
import Dashboard from "scenes/dashboard";
import Proyectos from "scenes/proyectos";
import Beneficiarios from "scenes/beneficiarios";
import Voluntariado from "scenes/voluntariado";
import Logistica from "scenes/logistica";
import Suministros from "scenes/suministros";
import GestionHumana from "scenes/gestionhumana";
import Admin from "scenes/admin";
import Upload from "scenes/cargar-datos";
//import Actividades from "scenes/proyectos/actividades";
import CrearProyecto from "scenes/proyectos/crearProyecto";
import EditarProyecto from "scenes/proyectos/editarProyecto";
import AdminEquipos from "scenes/proyectos/adminEquipos";
import AgregarMiembros from "scenes/proyectos/agregarMiembros";
import GestionProyectos from "scenes/proyectos/gestionProyectos";
import Estadisticas from "scenes/proyectos/estadisticas";
import CrearActividad from "scenes/voluntariado/crearActividad";
import EditarActividad from "scenes/voluntariado/editarActividad";
import AceptarUsuarios from "scenes/admin/aceptarUsuarios";
import Logros from "scenes/voluntariado/logros";
import Asistencia from "scenes/gestionhumana/asistencia";
import ReporteAsistencia from "scenes/gestionhumana/asistreporte";
import ListadoAsistencia from "scenes/gestionhumana/asistlista";
import EasterEgg from "scenes/easterEgg";
import SolicitudSum from "scenes/suministros/solicitudSum";
import ListaConvocatorias from "scenes/suministros/convoList";
import ReporteSuministros from "scenes/suministros/reportSum";
import ProcesarSolicitud from "scenes/suministros/procSolicitud";
import EditarSolicitud from "scenes/suministros/editarSolicitud";
import FinalizarSolicitud from "scenes/suministros/finSolicitud";
import Retroalimentacion from "scenes/gestionhumana/retroalimentacion";
import RetroConvocatorias from "scenes/gestionhumana/retroConvos";
import CrearRetro from "scenes/gestionhumana/crearRetro";
import ReporteSuministrosEntrada from "scenes/suministros/reportSumEnt";
import InfoPage from "scenes/register/info";
import PerfilVoluntario from "scenes/voluntariado/perfilVoluntario";
import Transcripcion from "scenes/transcripcion";
import ListaTranscripcion from "scenes/transcripcion/transLista";
import SaludForm from "scenes/transcripcion/saludForm";
import NutriForm from "scenes/transcripcion/nutriForm";
import EditarSalud from "scenes/transcripcion/editarSalud";
import EditarNutri from "scenes/transcripcion/editarNutri";
import ErrorPage from "scenes/login/error";
import DesktopNotification from "scenes/login/notiTest";
import EstadisticasGenerales from "scenes/proyectos/stats";
import Postulacion from "scenes/postulacion";
import Postulaciones from "scenes/gestionhumana/postulaciones";
import GeneradorFichas from "scenes/dashboard/genFichas";
import InfoReset from "scenes/resetPass/infoReset";
import Consolidado from "scenes/transcripcion/consolidado";
import PdfConsolidado from "scenes/transcripcion/pdfConsolidado";
import Maps from "scenes/logistica/maps";

function App() {
 const mode = useSelector((state) => state.global.mode);
 const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

 //Protección de rutas
 const ProtectedRoute = ({ redirectPath = '/accessDenied' }) => {
    if (localStorage.getItem('token') !== null) {
      return <Layout />;
    } else {
      return <Navigate to={redirectPath} replace />;
    }
  };

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/registro" element={<Registro />} />
            <Route path="/resetPass" element={<ResetPass />} />
            <Route path="/createPass" element={<CreatePass />} />
            <Route path="/postulacion" element={<Postulacion />} />
            <Route path="/info" element={<InfoPage />} />
            <Route path="/infoReset" element={<InfoReset />} />
            <Route path="/secret" element={<EasterEgg />} />
            <Route path="/accessDenied" element={<ErrorPage />} />
            <Route path="/notiTest" element={<DesktopNotification />} />
            <Route path="/pdfConsolidado" element={<PdfConsolidado />} />
              <Route element={<ProtectedRoute replace />}>
                {/*<Route path="/" element={<Navigate to="/inicio" replace />} />*/}
                <Route path="/inicio" element={<Dashboard />} />
                <Route path="/generadorFichas" element={<GeneradorFichas />} />
                <Route path="/proyectos" element={<Proyectos />} />
                <Route path="/beneficiarios" element={<Beneficiarios />} />
                <Route path="/gestionhumana" element={<GestionHumana />} />
                <Route path="/voluntariado" element={<Voluntariado />} />
                <Route path="/logistica" element={<Logistica />} />
                <Route path="/map" element={<Maps />} />
                <Route path="/suministros" element={<Suministros />} />
                <Route path="/editarSolicitudSum" element={<EditarSolicitud />} />
                <Route path="/procesarSolicitud" element={<ProcesarSolicitud />} />
                <Route path="/finalizarSolicitud" element={<FinalizarSolicitud />} />
                <Route path="/solicitudSuministros" element={<SolicitudSum />} />
                <Route path="/listaConvocatorias" element={<ListaConvocatorias />} />
                <Route path="/reporteSuministros" element={<ReporteSuministros />} />
                <Route path="/reporteSumEntrada" element={<ReporteSuministrosEntrada />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/crearProyecto" element={<CrearProyecto />} />
                <Route path="/editarProyecto" element={<EditarProyecto />} />
                <Route path="/adminEquipos" element={<AdminEquipos />} />
                <Route path="/agregarMiembros" element={<AgregarMiembros />} />
                <Route path="/gestionProyectos" element={<GestionProyectos />} />
                <Route path="/estadisticas" element={<Estadisticas />} />
                <Route path="/estadisticasGenerales" element={<EstadisticasGenerales />} />
                {/*<Route path="/actividades" element={<Actividades />} />*/}
                <Route path="/crearConvocatoria" element={<CrearActividad />} />
                <Route path="/editarConvocatoria" element={<EditarActividad />} />
                <Route path="/perfil" element={<PerfilVoluntario />} />
                <Route path="/logros" element={<Logros />} />
                <Route path="/aceptarUsuarios" element={<AceptarUsuarios />} />
                <Route path="/asistencia" element={<Asistencia />} />
                <Route path="/asistreporte" element={<ReporteAsistencia />} />
                <Route path="/asistlista" element={<ListadoAsistencia />} />
                <Route path="/retroalimentacion" element={<Retroalimentacion />} />
                <Route path="/retroConvocatorias" element={<RetroConvocatorias />} />
                <Route path="/postulaciones" element={<Postulaciones />} />
                <Route path="/transcripcion" element={<Transcripcion />} />
                <Route path="/listaTranscripcion" element={<ListaTranscripcion />} />
                <Route path="/saludFormulario" element={<SaludForm />} />
                <Route path="/nutriFormulario" element={<NutriForm />} />
                <Route path="/editarSalud" element={<EditarSalud />} />
                <Route path="/editarNutri" element={<EditarNutri />} />
                <Route path="/baseDatos" element={<Consolidado />} />
                <Route path="/crearRetroalimentacion" element={<CrearRetro />} />
                <Route path="/cargar-datos" element={<Upload />} /> 
              </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;