import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ChildCareOutlinedIcon from "@mui/icons-material/ChildCareOutlined";
import PregnantWomanOutlinedIcon from "@mui/icons-material/PregnantWomanOutlined";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import SchoolIcon from "@mui/icons-material/School";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { IMaskInput } from "react-imask";
import { ListSubheader, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Backdrop from "@mui/material/Backdrop";
import Restricted from "components/Restricted";
import CircularProgress from "@mui/material/CircularProgress";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";
import { animateScroll } from "react-scroll";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const allOptions = [
  "Pedro Pérez",
  "José González",
  "María Pérez",
  "Ana González",
];

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+00(#00) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const options = {
  smooth: true,
};

const NutriForm = () => {
  const nav = useNavigate();
  const topRef = useRef(null);
  const buttRef = useRef();
  const buttRefMel = useRef();
  const [ejecutar, setEjecutar] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyect = JSON.parse(token);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  // Deshabilitar Submit /////////////////////////////////////////////////
  const [disableBtnM5, setDisableBtnM5] = React.useState(false);
  const [disableBtnMel, setDisableBtnMel] = React.useState(false);
  const [disableBtnDes, setDisableBtnDes] = React.useState(false);
  const [disableBtnCap, setDisableBtnCap] = React.useState(false);
  ////////////////////////////////////////////////////////////////////////

  const [selectedOption, setSelectedOption] = useState(allOptions[0]);

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => allOptions.filter((option) => containsText(option, searchText)),
    [searchText]
  );

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //DatePicker Values
  const [fechaNac, setFechaNac] = React.useState(null);
  const [fechaEval, setFechaEval] = React.useState(
    proyect.actividad == "Control Nutricional" ? null : proyect.fecha
  );
  const [fechaNacMel, setFechaNacMel] = React.useState(null);
  const [fechaEvalMel, setFechaEvalMel] = React.useState(
    proyect.actividad == "Control Nutricional" ? null : proyect.fecha
  );
  const [fechaInicDesp, setFechaInicDesp] = React.useState(proyect.fecha);
  const [fechaFinalDesp, setFechaFinalDesp] = React.useState(proyect.fecha);
  const [fechaInicCap, setFechaInicCap] = React.useState(proyect.fecha);
  const [fechaFinalCap, setFechaFinalCap] = React.useState(proyect.fecha);

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
      nav("/proyectos");
    }, 2000);

  //Tabs Handlers
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  //Medicamentos Inputs
  const [medicamentos, setMedicamentos] = useState("");
  const [data, setData] = useState([{ medicamento: "", dosis: "" }]);
  const [activar, setActivar] = useState(true);
  let contador = 0;

  const handleClick = () => {
    setData([...data, { medicamento: "", dosis: "" }]);
    setActivar(true);
  };
  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
    for (let i = 0; i < data.length; i++) {
      if (data[i].medicamento !== "" && data[i].dosis !== "") {
      } else {
        contador = contador + 1;
      }
      if (contador !== 0) {
        setActivar(true);
      } else {
        setActivar(false);
      }
    }
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  //Medicamentos MEL Inputs
  const [dataMel, setDataMel] = useState([
    { medicamentoMel: "", dosisMel: "" },
  ]);
  const [activarMel, setActivarMel] = useState(true);
  let contadorMel = 0;

  const handleClickMel = () => {
    setDataMel([...dataMel, { medicamentoMel: "", dosisMel: "" }]);
    setActivarMel(true);
  };
  const handleChangeInputMel = (e, i) => {
    const { name, value } = e.target;
    const onchangeMel = [...dataMel];
    onchangeMel[i][name] = value;
    setDataMel(onchangeMel);
    for (let i = 0; i < dataMel.length; i++) {
      if (dataMel[i].medicamentoMel !== "" && dataMel[i].dosisMel !== "") {
      } else {
        contadorMel = contadorMel + 1;
      }
      if (contadorMel !== 0) {
        setActivarMel(true);
      } else {
        setActivarMel(false);
      }
    }
  };
  const handleDeleteMel = (i) => {
    const deleteMel = [...dataMel];
    deleteMel.splice(i, 1);
    setDataMel(deleteMel);
  };

  //Routes
  const handleLinkCancelar = (event, message) => {
    if (message === "cancelar") {
      nav(-1);
    }
  };

  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkTranscripcion = (event, message) => {
    if (message === "transcripcion") {
      nav("/transcripcion");
    }
  };

  const handleLinkConvos = (event, message) => {
    if (message === "convos") {
      nav(-1);
    }
  };

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Menores de 5 años Radio Buttons
  const [valueRL, setValueRL] = React.useState("");
  const handleChangeRL = (event) => {
    setValueRL(event.target.value);
  };

  const [biberon, setBiberon] = React.useState("");
  const handleChangeBiberon = (event) => {
    setBiberon(event.target.value);
  };

  const [otros, setOtros] = React.useState("");
  const handleChangeOtros = (event) => {
    setOtros(event.target.value);
  };

  const [hervida, setHervida] = React.useState("");
  const handleChangeHervida = (event) => {
    setHervida(event.target.value);
  };

  const [filtrada, setFiltrada] = React.useState("");
  const handleChangeFiltrada = (event) => {
    setFiltrada(event.target.value);
  };

  const [sinTrat, setSinTrat] = React.useState("");
  const handleChangeSinTrat = (event) => {
    setSinTrat(event.target.value);
  };

  const [gripe, setGripe] = React.useState("");
  const handleChangeGripe = (event) => {
    setGripe(event.target.value);
  };

  const [fiebre, setFiebre] = React.useState("");
  const handleChangeFiebre = (event) => {
    setFiebre(event.target.value);
  };

  const [diarrea, setDiarrea] = React.useState("");
  const handleChangeDiarrea = (event) => {
    setDiarrea(event.target.value);
  };

  const [vomito, setVomito] = React.useState("");
  const handleChangeVomito = (event) => {
    setVomito(event.target.value);
  };

  const [paludismo, setPaludismo] = React.useState("");
  const handleChangePaludismo = (event) => {
    setPaludismo(event.target.value);
  };

  const [valueDisc, setValueDisc] = React.useState("");
  const handleChangeDisc = (event) => {
    setValueDisc(event.target.value);
  };

  const [edema, setEdema] = React.useState("No");
  const handleChangeEdema = (event) => {
    if (event.target.value == "Si") {
      setDiagFinalM5("Desnutrición Aguda Severa");
      setPbM5("S/D");
      setClasificacionNutM5("S/D");
      setRutfM5("Si");
      setDosisMedicamentoM5("2 sobres diarios por 15 días");
      setEdema(event.target.value);
    } else {
      setEdema(event.target.value);
      setCmbM5("");
      setPuntajezM5("");
      setDiagFinalM5("");
      setPbM5("");
      setClasificacionNutM5("");
      setRutfM5("");
      setDosisMedicamentoM5("");
    }
  };

  const [consejM5, setConsejM5] = React.useState("Si");
  const handleChangeConsejM5 = (event) => {
    setConsejM5(event.target.value);
  };

  const [referidoM5, setReferidoM5] = React.useState("No");
  const handleReferidoM5 = (event) => {
    setReferidoM5(event.target.value);
    if (event.target.value === "Si") {
      setAlbendazolM5("No");
      setMicronutM5("No");
      setLnsmq("No");
      setRutfM5("No");
    } else {
      if (event.target.value === "No") {
        setAlbendazolM5("");
        setMicronutM5("");
        setLnsmq("");
        setRutfM5("");
      }
    }
  };

  const [albendazolM5, setAlbendazolM5] = React.useState("");
  const handleChangeAlbendazolM5 = (event) => {
    setAlbendazolM5(event.target.value);
  };

  const [micronutM5, setMicronutM5] = React.useState("");
  const handleChangeMicronutM5 = (event) => {
    if (event.target.value == "Si") {
      setMedicamentos("Micronutrientes");
      setMicronutM5(event.target.value);
      setLnsmq("No");
      setRutfM5("No");
    } else {
      setMicronutM5(event.target.value);
      setLnsmq("");
      setRutfM5("");
    }
  };

  const [lnsmq, setLnsmq] = React.useState("");
  const handleChangeLnsmq = (event) => {
    if (event.target.value == "Si") {
      setMedicamentos("Lmsq");
      setMicronutM5("No");
      setLnsmq(event.target.value);
      setRutfM5("No");
    } else {
      setLnsmq(event.target.value);
      setMicronutM5("");
      setRutfM5("");
    }
  };

  const [rutfM5, setRutfM5] = React.useState("");
  const handleChangeRutfM5 = (event) => {
    if (event.target.value == "Si") {
      setMedicamentos("Rutf");
      setMicronutM5("No");
      setLnsmq("No");
      setRutfM5(event.target.value);
    } else {
      setRutfM5(event.target.value);
      setMicronutM5("");
      setLnsmq("");
    }
  };

  const [kitHigieneM5, setKitHigieneM5] = React.useState("");
  const handleKitHigieneM5 = (event) => {
    setKitHigieneM5(event.target.value);
  };

  const [kitRepoM5, setKitRepoM5] = React.useState("");
  const handleKitRepoM5 = (event) => {
    setKitRepoM5(event.target.value);
  };

  //MEL Radio Buttons
  const [criterioMel, setCriterioMel] = React.useState("No");
  const handleCriterioMel = (event) => {
    if (event.target.value == "Si") {
      setDiagFinalMel("Bajo Peso");
      setCpbMel("S/D");
      setDiagIngresoMel("S/D");
      setAlbendazolMel("Si");
      setAlbendazolDosisMel("Dosis única (400mg)");
      setMicronutMel("No");
      setLnmsqMel("Si");
      setPlumpyMel("No");
      setDosisMedicamentoMel("1 tab diaria por 30 días");
      setMedicamentosMel("LNS-MQ");
      setCriterioMel(event.target.value);
    } else {
      setCriterioMel(event.target.value);
      setDiagFinalMel("");
      setCpbMel("");
      setDiagIngresoMel("");
      setAlbendazolMel("");
      setAlbendazolDosisMel("");
      setMicronutMel("");
      setLnmsqMel("");
      setPlumpyMel("");
      setDosisMedicamentoMel("");
      setMedicamentosMel("");
    }
  };

  const [consejMel, setConsejMel] = React.useState("Si");
  const handleConsejMel = (event) => {
    setConsejMel(event.target.value);
  };

  const [referidoMel, setReferidoMel] = React.useState("No");
  const handleReferidoMel = (event) => {
    setReferidoMel(event.target.value);
    if (event.target.value === "Si") {
      setAlbendazolMel("No");
      setMicronutMel("No");
      setLnmsqMel("No");
      setPlumpyMel("No");
    } else {
      if (event.target.value === "No") {
        setAlbendazolMel("");
        setMicronutMel("");
        setLnmsqMel("");
        setPlumpyMel("");
      }
    }
  };

  const [albendazolMel, setAlbendazolMel] = React.useState("");
  const handleAlbendazolMel = (event) => {
    setAlbendazolMel(event.target.value);
  };

  const [micronutMel, setMicronutMel] = React.useState("");
  const handleMicronutMel = (event) => {
    if (event.target.value == "Si") {
      setMedicamentosMel("Micronutrientes");
      setMicronutMel(event.target.value);
      setLnmsqMel("No");
      setPlumpyMel("No");
    } else {
      setMicronutMel(event.target.value);
      setLnmsqMel("");
      setPlumpyMel("");
    }
  };

  const [lnsmqMel, setLnmsqMel] = React.useState("");
  const handleLnsmqMel = (event) => {
    if (event.target.value == "Si") {
      setMedicamentosMel("Lmsq");
      setMicronutMel("No");
      setLnmsqMel(event.target.value);
      setPlumpyMel("No");
    } else {
      setLnmsqMel(event.target.value);
      setMicronutMel("");
      setPlumpyMel("");
    }
  };

  const [plumpyMel, setPlumpyMel] = React.useState("");
  const handlePlumpyMel = (event) => {
    if (event.target.value == "Si") {
      setMedicamentosMel("Plumpy");
      setMicronutMel("No");
      setLnmsqMel("No");
      setPlumpyMel(event.target.value);
    } else {
      setPlumpyMel(event.target.value);
      setMicronutMel("");
      setLnmsqMel("");
    }
  };

  const [kitHigieneMel, setKitHigieneMel] = React.useState("");
  const handleKitHigieneMel = (event) => {
    setKitHigieneMel(event.target.value);
  };

  const [kitRepoMel, setKitRepoMel] = React.useState("");
  const handleKitRepoMel = (event) => {
    setKitRepoMel(event.target.value);
  };

  //Menores de 5 años Input States
  const [estadoM5, setEstadoM5] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.estadoConvo
  );
  const [municipioM5, setMunicipioM5] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.municipio
  );
  const [parroquiaM5, setParroquiaM5] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.parroquia
  );
  const [centroM5, setCentroM5] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.comunidad
  );
  const [comunidadM5, setComunidadM5] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.comunidad
  );
  const [ingresoM5, setIngresoM5] = useState("");
  const [representanteM5, setRepresentanteM5] = useState("");
  const [telfRepM5, setTelfRepM5] = useState({ textmask: "" });
  const handleTelfRepM5 = (event) => {
    setTelfRepM5({
      ...telfRepM5,
      [event.target.name]: event.target.value,
    });
  };
  const [cedulaRepM5, setCedulaRepM5] = useState("");
  const [nombreBenefM5, setNombreBenefM5] = useState("");
  const [apellidoBenefM5, setApellidoBenefM5] = useState("");
  const [edadM5, setEdadM5] = useState("");
  //////////////////////// EDAD EN MESES /////////////////////////////

  useEffect(() => {
    if (fechaNac != null) {
      const currentDate = new Date(fechaEval);
      const diffTime = currentDate - new Date(fechaNac);
      const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      setEdadM5(
        Math.floor(totalDays / 365.25) +
          " año(s) y " +
          Math.floor((totalDays % 365.25) / 30.4375) +
          " mes(es)"
      );
    } else {
      setEdadM5("");
    }
  }, [fechaNac]);

  /////////////////////////////////////////////////////////////////
  const [vecesDiaM5, setVecesDiaM5] = useState("");
  const [cualM5, setCualM5] = useState("");

  /////////// VARIABLES COMPARACIÓN DE DIAGNÓSTICOS /////////////
  const [diagPbM5, setDiagPbM5] = useState(0);
  const [diagNutM5, setDiagNutM5] = useState(0);
  const [diagPbMel, setDiagPbMel] = useState(0);
  const [diagImcMel, setDiagImcMel] = useState(0);
  //////////////////////////////////////////////////////////////

  const [cmbM5, setCmbM5] = useState("");
  const handleCmbM5 = (event) => {
    setCmbM5(event.target.value.replace(/[d]/g, "").slice(0, 6));
    /*if (Number(event.target.value) >= 18 && edema !== "Si") {
      setPbM5("Sobrepeso");
      setDiagPbM5(3);
    } else {*/
      if (
        Number(event.target.value) <= 30 &&
        Number(event.target.value) > 12.5 &&
        edema !== "Si"
      ) {
        setPbM5("Peso Adecuado");
        setDiagPbM5(5);
      } else {
        if (
          Number(event.target.value) <= 12.5 &&
          Number(event.target.value) >= 11.5 &&
          edema !== "Si"
        ) {
          setPbM5("Desnutrición Aguda Moderada");
          setDiagPbM5(2);
        } else {
          if (
            Number(event.target.value) < 11.5 &&
            Number(event.target.value) >= 9 &&
            edema !== "Si"
          ) {
            setPbM5("Desnutrición Aguda Severa");
            setDiagPbM5(1);
          } else {
            if (event.target.value === "" && edema !== "Si") {
              setPbM5("");
              setDiagPbM5(0);
            }
          }
        }
      }
    /*}*/
  };

  const [pbM5, setPbM5] = useState("");
  const [pesoM5, setPesoM5] = useState("");
  const [tallaM5, setTallaM5] = useState("");
  const [clasificacionNutM5, setClasificacionNutM5] = useState("");
  const [diagFinalM5, setDiagFinalM5] = useState("");
  const [dosisAlbendazolM5, setDosisAlbendazolM5] = useState("");
  const [dosisMedicamentoM5, setDosisMedicamentoM5] = useState("");
  const [medicoM5, setMedicoM5] = useState("");
  const [observacionesM5, setObservacionesM5] = useState(
    proyect.actividad == "Control Nutricional"
      ? "Ingresado en actividad de control nutricional"
      : ""
  );

  const [amoxM5, setAmoxM5] = React.useState("");
  const handleChangeAmoxM5 = (event) => {
    setAmoxM5(event.target.value);
    if (diagFinalM5 !== "Desnutrición Aguda Severa") {
      setAmoxM5("No");
    }
  };

  //MEL input states
  const [estadoMel, setEstadoMel] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.estadoConvo
  );
  const [municipioMel, setMunicipioMel] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.municipio
  );
  const [parroquiaMel, setParroquiaMel] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.parroquia
  );
  const [centroMel, setCentroMel] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.comunidad
  );
  const [comunidadMel, setComunidadMel] = useState(
    proyect.actividad == "Control Nutricional" ? "" : proyect.comunidad
  );
  const [cedulaMel, setCedulaMel] = useState("");
  const [nombresMel, setNombresMel] = useState("");
  const [apellidosMel, setApellidosMel] = useState("");
  const [telfMel, setTelfMel] = useState({ textmask: "" });
  const handleTelfMel = (event) => {
    setTelfMel({
      ...telfMel,
      [event.target.name]: event.target.value,
    });
  };
  const [semanasGest, setSemGestMel] = useState("");
  const handleSemanasGest = (event) => {
    setSemGestMel(event.target.value);
  };
  const [diagIngresoMel, setDiagIngresoMel] = useState("");
  const [diagFinalMel, setDiagFinalMel] = useState("");
  const [diagSemGestMel, setDiagSemGestMel] = useState("");
  const [edadMel, setEdadMel] = useState("");

  const [tallaMel, setTallaMel] = useState("");
  const handleTallaMel = (event) => {
    setTallaMel(event.target.value);
  };

  const [pesoMel, setPesoMel] = useState("");
  const handlePesoMel = (event) => {
    setPesoMel(event.target.value);
  };

  ///////////////// EDAD EN AÑOS ///////////////////
  useEffect(() => {
    let currentDate = new Date(fechaEvalMel);
    let diffTime = currentDate - new Date(fechaNacMel);
    let totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    let ageMel = Math.floor(totalDays / 365.25);
    if (isNaN(ageMel)) ageMel = 0;
    if (fechaNacMel != null) {
      setEdadMel(ageMel + " año(s)");
    } else {
      setEdadMel("");
    }
  }, [fechaNacMel]);
  ///////////////////////////////////////////////

  const [cmbMel, setCmbMel] = useState("");
  const handleCmbMel = (event) => {
    setCmbMel(event.target.value.replace(/[d]/g, "").slice(0, 6));
    if (
      Number(event.target.value) <= 22.1 &&
      event.target.value !== "" &&
      criterioMel !== "Si"
    ) {
      setCpbMel("Bajo Peso");
      setDiagPbMel(1);
    } else {
      if (
        Number(event.target.value) > 22.1 &&
        Number(event.target.value) < 33.09 &&
        criterioMel !== "Si"
      ) {
        setCpbMel("Peso adecuado");
        setDiagPbMel(3);
      } else {
        if (Number(event.target.value) >= 33.1 && criterioMel !== "Si") {
          setCpbMel("Obesidad");
          setDiagPbMel(5);
        } else {
          if (event.target.value === "" && criterioMel !== "Si") {
            setCpbMel("");
            setDiagPbMel(0);
          }
        }
      }
    }
  };
  const [cpbMel, setCpbMel] = useState("");
  const [dosisMedicamentoMel, setDosisMedicamentoMel] = useState("");
  const [medicamentoMel, setMedicamentosMel] = useState("");
  const [observacionesMel, setObservacionesMel] = useState(
    proyect.actividad == "Control Nutricional"
      ? "Ingresado en actividad de control nutricional"
      : ""
  );
  const [medicoMel, setMedicoMel] = useState("");

  //Desparasitaciones input states
  const [nombreCentroDesp, setNombreCentroDesp] = useState(proyect.comunidad);
  const [parroquiaDesp, setParroquiaDesp] = useState(proyect.parroquia);
  const [municipioDesp, setMunicipioDesp] = useState(proyect.municipio);
  const [estadoDesp, setEstadoDesp] = useState(proyect.estadoConvo);
  const [comunidadDesp, setComunidadDesp] = useState(proyect.comunidad);
  const [ninosDesp, setNinosDesp] = useState("");
  const [ninasDesp, setNinasDesp] = useState("");
  const [escolaridadDesp, setEscolaridadDesp] = useState("");

  //Capacitaciones input states
  const [nombreCentroCap, setNombreCentroCap] = useState(proyect.comunidad);
  const [parroquiaCap, setParroquiaCap] = useState(proyect.parroquia);
  const [municipioCap, setMunicipioCap] = useState(proyect.municipio);
  const [estadoCap, setEstadoCap] = useState(proyect.estadoConvo);
  const [comunidadCap, setComunidadCap] = useState(proyect.comunidad);
  const [latitudCap, setLatitudCap] = useState(proyect.latitud);
  const [longitudCap, setLongitudCap] = useState(proyect.longitud);
  const [sdCap, setSdCap] = useState("");
  const [hombres017Cap, setHombres017Cap] = useState("");
  const [mujeres017Cap, setMujeres017Cap] = useState("");
  const [hombres1859Cap, setHombres1859Cap] = useState("");
  const [mujeres1859Cap, setMujeres1859Cap] = useState("");
  const [hombres60Cap, setHombres60Cap] = useState("");
  const [mujeres60Cap, setMujeres60Cap] = useState("");
  const [indigenaCap, setIndigenaCap] = useState("");
  const [mestizoCap, setMestizoCap] = useState("");
  const [sinInformacionCap, setSinInformacionCap] = useState("");
  const [discapacitadosCap, setDiscapacitadosCap] = useState("");
  const [lgbtqiCap, setLgbtqiCap] = useState("");
  //const [totalCap, setTotalCap] = useState("");

  //Select States
  const [grupoEtnicoM5, setGrupoEtnicoM5] = React.useState("");
  const handleGrupoEtnicoM5 = (event) => {
    setGrupoEtnicoM5(event.target.value);
  };

  const [albendazolDosisM5, setAlbendazolDosisM5] = React.useState("");
  const handleAlbendazolDosisM5 = (event) => {
    setAlbendazolDosisM5(event.target.value);
  };

  const [grupoEtnicoMel, setGrupoEtnicoMel] = React.useState("");
  const handleGrupoEtnicoMel = (event) => {
    setGrupoEtnicoMel(event.target.value);
  };

  const [albendazolDosisMel, setAlbendazolDosisMel] = React.useState("");
  const handleAlbendazolDosisMel = (event) => {
    setAlbendazolDosisMel(event.target.value);
  };

  const [gestante, setGestante] = React.useState("");
  const handleGestante = (event) => {
    setGestante(event.target.value);
    if (event.target.value === "Si") {
      setLactancia("No");
      setSemGestMel("");
    } else {
      if (event.target.value === "No") {
        setLactancia("Si");
        setSemGestMel("No");
        setCriterioMel("No");
        setCpbMel("");
        setDiagIngresoMel("");
        setDiagFinalMel("");
      }
    }
  };

  const [lactancia, setLactancia] = React.useState("");
  const handleLactancia = (event) => {
    setLactancia(event.target.value);
  };

  const [genero, setGenero] = React.useState("");
  const handleChangeGenero = (event) => {
    setGenero(event.target.value);
  };

  const [tipoIngreso, setTipoIngreso] = React.useState("");
  const handleTipoIngreso = (event) => {
    setTipoIngreso(event.target.value);
  };

  const [registradoM5, setRegistradoM5] = React.useState("");
  const handleRegistrado = (event) => {
    setRegistradoM5(event.target.value);
  };

  const [escolarizadoM5, setEscolarizadoM5] = React.useState("");
  const handleEscolarizado = (event) => {
    setEscolarizadoM5(event.target.value);
  };

  const [puntajezM5, setPuntajezM5] = React.useState("");
  const handlePuntajeZ = (event) => {
    setPuntajezM5(event.target.value.replace(/[d]/g, "").slice(0, 6));
    if (
      Number(event.target.value) <= -2.9 &&
      Number(event.target.value) >= -4.5 &&
      edema !== "Si"
    ) {
      setClasificacionNutM5("Desnutrición Aguda Severa");
      setDiagNutM5(1);
    } else {
      if (
        Number(event.target.value) <= -1.9 &&
        Number(event.target.value) >= -2.89 &&
        edema !== "Si"
      ) {
        setClasificacionNutM5("Desnutrición Aguda Moderada");
        setDiagNutM5(2);
      } else {
        if (
          Number(event.target.value) <= 2.00 &&
          Number(event.target.value) >= -1.89 &&
          event.target.value !== "" &&
          edema !== "Si"
        ) {
          setClasificacionNutM5("Adecuado");
          setDiagNutM5(5);
        } else {
          if (
            Number(event.target.value) <= 2.99 &&
            Number(event.target.value) >= 2.01 &&
            edema !== "Si"
          ) {
            setClasificacionNutM5("Soprepeso");
            setDiagNutM5(3);
          } else {
            if (Number(event.target.value) >= 3 && edema !== "Si") {
              setClasificacionNutM5("Obesidad");
              setDiagNutM5(4);
            } else {
              if (event.target.value === "" && edema !== "Si") {
                setClasificacionNutM5("");
                setDiagNutM5(0);
              }
            }
          }
        }
      }
    }
  };

  //////////////// COMPARACIÓN DIAGNÓSTICOS M5 ///////////////
  useEffect(() => {
    if (diagPbM5 === "" || (diagNutM5 === "" && edema !== "Si")) {
      setDiagFinalM5("");
    } else {
      if (diagPbM5 < diagNutM5 && edema !== "Si") {
        setDiagFinalM5(pbM5);
      } else {
        if (diagPbM5 > diagNutM5 && edema !== "Si") {
          setDiagFinalM5(clasificacionNutM5);
        } else {
          if (diagPbM5 == diagNutM5 && edema !== "Si") {
            setDiagFinalM5(clasificacionNutM5);
          }
        }
      }
    }
  }, [diagPbM5, diagNutM5]);
  //////////////////////////////////////////////////////////

  const [ingresoMel, setIngresoMel] = React.useState("");
  const handleIngresoMel = (event) => {
    setIngresoMel(event.target.value);
  };

  const [equipo, setEquipo] = React.useState("");
  const handleEquipo = (event) => {
    setEquipo(event.target.value);
  };

  const [equipoCap, setEquipoCap] = React.useState("");
  const handleEquipoCap = (event) => {
    setEquipoCap(event.target.value);
  };

  //Arrays IMC
  const sem6Opc = [
    {
      value: "< 20.0",
      label: "< 20.0",
    },
    {
      value: "20.0 - 24.9",
      label: "20.0 - 24.9",
    },
    {
      value: "25.0 - 30.0",
      label: "25.0 - 30.0",
    },
    {
      value: "> 30.0",
      label: "> 30.0",
    },
  ];
  const sem7Opc = [
    {
      value: "< 20.1",
      label: "< 20.1",
    },
    {
      value: "20.1 - 24.9",
      label: "20.1 - 24.9",
    },
    {
      value: "25.0 - 30.0",
      label: "25.0 - 30.0",
    },
    {
      value: "> 30.0",
      label: "> 30.0",
    },
  ];
  const sem8Opc = [
    {
      value: "< 20.2",
      label: "< 20.2",
    },
    {
      value: "20.2 - 24.9",
      label: "20.2 - 24.9",
    },
    {
      value: "25.1 - 30.1",
      label: "25.1 - 30.1",
    },
    {
      value: "> 30.1",
      label: "> 30.1",
    },
  ];
  const sem9Opc = [
    {
      value: "< 20.2",
      label: "< 20.2",
    },
    {
      value: "20.2 - 25",
      label: "20.2 - 25",
    },
    {
      value: "25.2 - 30.2",
      label: "25.2 - 30.2",
    },
    {
      value: "> 30.2",
      label: "> 30.2",
    },
  ];
  const sem10Opc = [
    {
      value: "< 20.3",
      label: "< 20.3",
    },
    {
      value: "20.3 - 25.2",
      label: "20.3 - 25.2",
    },
    {
      value: "25.3 - 30.2",
      label: "25.3 - 30.2",
    },
    {
      value: "> 30.2",
      label: "> 30.2",
    },
  ];
  const sem11Opc = [
    {
      value: "< 20.4",
      label: "< 20.4",
    },
    {
      value: "20.4 - 25.3",
      label: "20.4 - 25.3",
    },
    {
      value: "25.4 - 30.3",
      label: "25.4 - 30.3",
    },
    {
      value: "> 30.3",
      label: "> 30.3",
    },
  ];
  const sem12Opc = [
    {
      value: "< 20.5",
      label: "< 20.5",
    },
    {
      value: "20.5 - 25.4",
      label: "20.5 - 25.4",
    },
    {
      value: "25.5 - 30.3",
      label: "25.5 - 30.3",
    },
    {
      value: "> 30.3",
      label: "> 30.3",
    },
  ];
  const sem13Opc = [
    {
      value: "< 20.7",
      label: "< 20.7",
    },
    {
      value: "20.7 - 25.6",
      label: "20.7 - 25.6",
    },
    {
      value: "25.7 - 30.4",
      label: "25.7 - 30.4",
    },
    {
      value: "> 30.4",
      label: "> 30.4",
    },
  ];
  const sem14Opc = [
    {
      value: "< 20.8",
      label: "< 20.8",
    },
    {
      value: "20.8 - 25.7",
      label: "20.8 - 25.7",
    },
    {
      value: "25.8 - 30.5",
      label: "25.8 - 30.5",
    },
    {
      value: "> 30.5",
      label: "> 30.5",
    },
  ];
  const sem15Opc = [
    {
      value: "< 20.9",
      label: "< 20.9",
    },
    {
      value: "20.9 - 25.8",
      label: "20.9 - 25.8",
    },
    {
      value: "25.9 - 30.6",
      label: "25.9 - 30.6",
    },
    {
      value: "> 30.6",
      label: "> 30.6",
    },
  ];
  const sem16Opc = [
    {
      value: "< 21.1",
      label: "< 21.1",
    },
    {
      value: "21.1 - 25.9",
      label: "21.1 - 25.9",
    },
    {
      value: "26 - 30.7",
      label: "26 - 30.7",
    },
    {
      value: "> 30.7",
      label: "> 30.7",
    },
  ];
  const sem17Opc = [
    {
      value: "< 21.2",
      label: "< 21.2",
    },
    {
      value: "21.2 - 26",
      label: "21.2 - 26",
    },
    {
      value: "26.1 - 30.8",
      label: "26.1 - 30.8",
    },
    {
      value: "> 30.8",
      label: "> 30.8",
    },
  ];
  const sem18Opc = [
    {
      value: "< 21.3",
      label: "< 21.3",
    },
    {
      value: "21.3 - 26.1",
      label: "21.3 - 26.1",
    },
    {
      value: "26.2 - 30.9",
      label: "26.2 - 30.9",
    },
    {
      value: "> 30.9",
      label: "> 30.9",
    },
  ];
  const sem19Opc = [
    {
      value: "< 21.3",
      label: "< 21.3",
    },
    {
      value: "21.5 - 26.2",
      label: "21.5 - 26.2",
    },
    {
      value: "26.3 - 30.9",
      label: "26.3 - 30.9",
    },
    {
      value: "> 30.9",
      label: "> 30.9",
    },
  ];
  const sem20Opc = [
    {
      value: "< 21.6",
      label: "< 21.6",
    },
    {
      value: "21.6 - 26.3",
      label: "21.6 - 26.3",
    },
    {
      value: "26.4 - 31.0",
      label: "26.4 - 31.0",
    },
    {
      value: "> 31.0",
      label: "> 31.0",
    },
  ];
  const sem21Opc = [
    {
      value: "< 21.8",
      label: "< 21.8",
    },
    {
      value: "21.8 - 26.4",
      label: "21.8 - 26.4",
    },
    {
      value: "26.5 - 31.1",
      label: "26.5 - 31.1",
    },
    {
      value: "> 31.1",
      label: "> 31.1",
    },
  ];
  const sem22Opc = [
    {
      value: "< 21.9",
      label: "< 21.9",
    },
    {
      value: "21.9 - 26.6",
      label: "21.9 - 26.6",
    },
    {
      value: "26.7 - 31.2",
      label: "26.7 - 31.2",
    },
    {
      value: "> 31.2",
      label: "> 31.2",
    },
  ];
  const sem23Opc = [
    {
      value: "< 22.1",
      label: "< 22.1",
    },
    {
      value: "22.1 - 26.7",
      label: "22.1 - 26.7",
    },
    {
      value: "26.8 - 31.3",
      label: "26.8 - 31.3",
    },
    {
      value: "> 31.3",
      label: "> 31.3",
    },
  ];
  const sem24Opc = [
    {
      value: "< 22.3",
      label: "< 22.3",
    },
    {
      value: "22.3 - 26.9",
      label: "22.3 - 26.9",
    },
    {
      value: "27.0 - 31.5",
      label: "27.0 - 31.5",
    },
    {
      value: "> 31.5",
      label: "> 31.5",
    },
  ];
  const sem25Opc = [
    {
      value: "< 22.5",
      label: "< 22.5",
    },
    {
      value: "22.5 - 27.0",
      label: "22.5 - 27.0",
    },
    {
      value: "27.1 - 31.6",
      label: "27.1 - 31.6",
    },
    {
      value: "> 31.6",
      label: "> 31.6",
    },
  ];
  const sem26Opc = [
    {
      value: "< 22.7",
      label: "< 22.7",
    },
    {
      value: "22.7 - 27.2",
      label: "22.7 - 27.2",
    },
    {
      value: "27.2 - 31.7",
      label: "27.2 - 31.7",
    },
    {
      value: "> 31.7",
      label: "> 31.7",
    },
  ];
  const sem27Opc = [
    {
      value: "< 22.8",
      label: "< 22.8",
    },
    {
      value: "22.8 - 27.3",
      label: "22.8 - 27.3",
    },
    {
      value: "27.4 - 31.8",
      label: "27.4 - 31.8",
    },
    {
      value: "> 31.8",
      label: "> 31.8",
    },
  ];
  const sem28Opc = [
    {
      value: "< 23.0",
      label: "< 23.0",
    },
    {
      value: "23.0 - 27.5",
      label: "23.0 - 27.5",
    },
    {
      value: "27.6 - 31.9",
      label: "27.6 - 31.9",
    },
    {
      value: "> 31.9",
      label: "> 31.9",
    },
  ];
  const sem29Opc = [
    {
      value: "< 23.2",
      label: "< 23.2",
    },
    {
      value: "23.2 - 27.6",
      label: "23.2 - 27.6",
    },
    {
      value: "27.7 - 32.0",
      label: "27.7 - 32.0",
    },
    {
      value: "> 32.0",
      label: "> 32.0",
    },
  ];
  const sem30Opc = [
    {
      value: "< 23.4",
      label: "< 23.4",
    },
    {
      value: "23.4 - 27.8",
      label: "23.4 - 27.8",
    },
    {
      value: "27.9 - 32.1",
      label: "27.9 - 32.1",
    },
    {
      value: "> 32.1",
      label: "> 32.1",
    },
  ];
  const sem31Opc = [
    {
      value: "< 23.5",
      label: "< 23.5",
    },
    {
      value: "23.5 - 27.9",
      label: "23.5 - 27.9",
    },
    {
      value: "28.0 - 32.2",
      label: "28.0 - 32.2",
    },
    {
      value: "> 32.2",
      label: "> 32.2",
    },
  ];
  const sem32Opc = [
    {
      value: "< 23.7",
      label: "< 23.7",
    },
    {
      value: "23.7 - 28.0",
      label: "23.7 - 28.0",
    },
    {
      value: "28.1 - 32.3",
      label: "28.1 - 32.3",
    },
    {
      value: "> 32.3",
      label: "> 32.3",
    },
  ];
  const sem33Opc = [
    {
      value: "< 23.9",
      label: "< 23.9",
    },
    {
      value: "23.9 - 28.1",
      label: "23.9 - 28.1",
    },
    {
      value: "28.2 - 32.4",
      label: "28.2 - 32.4",
    },
    {
      value: "> 32.4",
      label: "> 32.4",
    },
  ];
  const sem34Opc = [
    {
      value: "< 24.0",
      label: "< 24.0",
    },
    {
      value: "24.0 - 28.3",
      label: "24.0 - 28.3",
    },
    {
      value: "28.4 - 32.5",
      label: "28.4 - 32.5",
    },
    {
      value: "> 32.5",
      label: "> 32.5",
    },
  ];
  const sem35Opc = [
    {
      value: "< 24.2",
      label: "< 24.2",
    },
    {
      value: "24.2 - 28.4",
      label: "24.2 - 28.4",
    },
    {
      value: "28.5 - 32.6",
      label: "28.5 - 32.6",
    },
    {
      value: "> 32.6",
      label: "> 32.6",
    },
  ];
  const sem36Opc = [
    {
      value: "< 24.3",
      label: "< 24.3",
    },
    {
      value: "24.3 - 28.5",
      label: "24.3 - 28.5",
    },
    {
      value: "28.6 - 32.7",
      label: "28.6 - 32.7",
    },
    {
      value: "> 32.7",
      label: "> 32.7",
    },
  ];
  const sem37Opc = [
    {
      value: "< 24.5",
      label: "< 24.5",
    },
    {
      value: "24.5 - 28.7",
      label: "24.5 - 28.7",
    },
    {
      value: "28.8 - 32.8",
      label: "28.8 - 32.8",
    },
    {
      value: "> 32.8",
      label: "> 32.8",
    },
  ];
  const sem38Opc = [
    {
      value: "< 24.6",
      label: "< 24.6",
    },
    {
      value: "24.6 - 28.8",
      label: "24.6 - 28.8",
    },
    {
      value: "28.9 - 32.9",
      label: "28.9 - 32.9",
    },
    {
      value: "> 32.9",
      label: "> 32.9",
    },
  ];
  const sem39Opc = [
    {
      value: "< 24.8",
      label: "< 24.8",
    },
    {
      value: "24.8 - 28.9",
      label: "24.8 - 28.9",
    },
    {
      value: "29.0 - 33.0",
      label: "29.0 - 33.0",
    },
    {
      value: "> 33.0",
      label: "> 33.0",
    },
  ];
  const sem40Opc = [
    {
      value: "< 25.0",
      label: "< 25.0",
    },
    {
      value: "25.0 - 29.1",
      label: "25.0 - 29.1",
    },
    {
      value: "29.2 - 33.1",
      label: "29.2 - 33.1",
    },
    {
      value: "> 33.1",
      label: "> 33.1",
    },
  ];
  const sem41Opc = [
    {
      value: "< 25.1",
      label: "< 25.1",
    },
    {
      value: "25.1 - 29.2",
      label: "25.1 - 29.2",
    },
    {
      value: "29.3 - 33.2",
      label: "29.3 - 33.2",
    },
    {
      value: "> 33.3",
      label: "> 33.3",
    },
  ];
  const sem42Opc = [
    {
      value: "< 25.1",
      label: "< 25.1",
    },
    {
      value: "25.1 - 29.2",
      label: "25.1 - 29.2",
    },
    {
      value: "29.3 - 33.2",
      label: "29.3 - 33.2",
    },
    {
      value: "> 33.3",
      label: "> 33.3",
    },
  ];

  //IMC Select Handlers
  /*const [semanasGest, setSemanasGest] = React.useState("");
  const handleSemanasGest = (event) => {
    setSemanasGest(event.target.value);
    if (event.target.value === "6") {
      setImcIngreso(sem6Opc);
    }
    if (event.target.value === "7") {
      setImcIngreso(sem7Opc);
    }
    if (event.target.value === "8") {
      setImcIngreso(sem8Opc);
    }
    if (event.target.value === "9") {
      setImcIngreso(sem9Opc);
    }
    if (event.target.value === "10") {
      setImcIngreso(sem10Opc);
    }
    if (event.target.value === "11") {
      setImcIngreso(sem11Opc);
    }
    if (event.target.value === "12") {
      setImcIngreso(sem12Opc);
    }
    if (event.target.value === "13") {
      setImcIngreso(sem13Opc);
    }
    if (event.target.value === "14") {
      setImcIngreso(sem14Opc);
    }
    if (event.target.value === "15") {
      setImcIngreso(sem15Opc);
    }
    if (event.target.value === "16") {
      setImcIngreso(sem16Opc);
    }
    if (event.target.value === "17") {
      setImcIngreso(sem17Opc);
    }
    if (event.target.value === "18") {
      setImcIngreso(sem18Opc);
    }
    if (event.target.value === "19") {
      setImcIngreso(sem19Opc);
    }
    if (event.target.value === "20") {
      setImcIngreso(sem20Opc);
    }
    if (event.target.value === "21") {
      setImcIngreso(sem21Opc);
    }
    if (event.target.value === "22") {
      setImcIngreso(sem22Opc);
    }
    if (event.target.value === "23") {
      setImcIngreso(sem23Opc);
    }
    if (event.target.value === "24") {
      setImcIngreso(sem24Opc);
    }
    if (event.target.value === "25") {
      setImcIngreso(sem25Opc);
    }
    if (event.target.value === "26") {
      setImcIngreso(sem26Opc);
    }
    if (event.target.value === "27") {
      setImcIngreso(sem27Opc);
    }
    if (event.target.value === "28") {
      setImcIngreso(sem28Opc);
    }
    if (event.target.value === "29") {
      setImcIngreso(sem29Opc);
    }
    if (event.target.value === "30") {
      setImcIngreso(sem30Opc);
    }
    if (event.target.value === "31") {
      setImcIngreso(sem31Opc);
    }
    if (event.target.value === "32") {
      setImcIngreso(sem32Opc);
    }
    if (event.target.value === "33") {
      setImcIngreso(sem33Opc);
    }
    if (event.target.value === "34") {
      setImcIngreso(sem34Opc);
    }
    if (event.target.value === "35") {
      setImcIngreso(sem35Opc);
    }
    if (event.target.value === "36") {
      setImcIngreso(sem36Opc);
    }
    if (event.target.value === "37") {
      setImcIngreso(sem37Opc);
    }
    if (event.target.value === "38") {
      setImcIngreso(sem38Opc);
    }
    if (event.target.value === "39") {
      setImcIngreso(sem39Opc);
    }
    if (event.target.value === "40") {
      setImcIngreso(sem40Opc);
    }
    if (event.target.value === "41") {
      setImcIngreso(sem41Opc);
    }
    if (event.target.value === "42") {
      setImcIngreso(sem42Opc);
    }
    if (event.target.value === "") {
      setImcIngreso([]);
    }
  };*/

  const [imcIngreso, setImcIngreso] = React.useState([]);
  const [selectImc, setSelectImc] = React.useState("");
  const handleImcIngreso = (event) => {
    setSelectImc(event.target.value.replace(/[d]/g, "").slice(0, 6));

    if (
      Number(event.target.value) < 18.5 &&
      event.target.value !== "" &&
      criterioMel !== "Si"
    ) {
      setDiagIngresoMel("Bajo Peso");
      setDiagImcMel(1);
    } else {
      if (
        Number(event.target.value) >= 18.5 &&
        Number(event.target.value) <= 24.99 &&
        criterioMel !== "Si"
      ) {
        setDiagIngresoMel("Peso adecuado");
        setDiagImcMel(3);
      } else {
        if (
          Number(event.target.value) >= 25 &&
          Number(event.target.value) <= 29.99 &&
          criterioMel !== "Si"
        ) {
          setDiagIngresoMel("Sobrepeso");
          setDiagImcMel(4);
        } else {
          if (Number(event.target.value) >= 30 && criterioMel !== "Si") {
            setDiagIngresoMel("Obesidad");
            setDiagImcMel(5);
          } else {
            if (event.target.value === "" && criterioMel !== "Si") {
              setDiagIngresoMel("");
              setDiagImcMel(0);
            }
          }
        }
      }
    }
  };

  //////////////// COMPARACIÓN DIAGNÓSTICOS MEL ///////////////
  useEffect(() => {
    if (diagPbMel === "" || (diagImcMel === "" && criterioMel !== "Si")) {
      setDiagFinalMel("");
    } else {
      if (diagPbMel < diagImcMel && criterioMel !== "Si") {
        setDiagFinalMel(cpbMel);
      } else {
        if (diagPbMel > diagImcMel && criterioMel !== "Si") {
          setDiagFinalMel(diagIngresoMel);
        } else {
          if (diagPbMel == diagImcMel && criterioMel !== "Si") {
            setDiagFinalMel(diagIngresoMel);
          }
        }
      }
    }
  }, [diagPbMel, diagImcMel]);
  //////////////////////////////////////////////////////////

  /////////////// DOSIS AUTOMÁTICAS M5 ///////////////////////
  useEffect(() => {
    if (diagFinalM5 === "Adecuado" && edema !== "Si") {
      setMicronutM5("Si");
      setLnsmq("No");
      setRutfM5("No");
      setDosisMedicamentoM5("1 sobre diario por 90 días");
      setAlbendazolM5("Si");
      setAlbendazolDosisM5("");
      setMedicamentos("Micronutrientes");
    } else {
      if (diagFinalM5 === "Desnutrición Aguda Moderada" && edema !== "Si") {
        setRutfM5("Si");
        setMicronutM5("No");
        setLnsmq("No");
        setDosisMedicamentoM5("1 sobre diario por 15 días");
        setAlbendazolM5("No");
        setAlbendazolDosisM5("");
        setMedicamentos("RUTF");
      } else {
        if (diagFinalM5 === "Desnutrición Aguda Severa" && edema !== "Si") {
          setRutfM5("Si");
          setMicronutM5("No");
          setLnsmq("No");
          setDosisMedicamentoM5("2 sobres diarios por 15 días");
          setAlbendazolM5("No");
          setAlbendazolDosisM5("");
          setMedicamentos("RUTF");
        } else {
          if (diagFinalM5 === "" && edema !== "Si") {
            setAlbendazolM5("");
            setAlbendazolDosisM5("");
            setMicronutM5("");
            setRutfM5("");
            setLnsmq("");
            setDosisMedicamentoM5("");
          }
        }
      }
    }
  }, [diagFinalM5]);
  ////////////////////////////////////////////////////////////////////////////

  /////////////// DOSIS AUTOMÁTICAS MEL ///////////////////////
  useEffect(() => {
    if (diagFinalMel === "Bajo Peso") {
      if (gestante === "Si" && lactancia === "No" && criterioMel !== "Si") {
        setAlbendazolMel("Si");
        setAlbendazolDosisMel("Dosis única (400mg)");
        setLnmsqMel("Si");
        setMicronutMel("No");
        setPlumpyMel("No");
        setDosisMedicamentoMel("1 tab diaria por 30 días");
        setMedicamentosMel("LNS-MQ");
      } else {
        if (diagFinalMel === "Bajo Peso") {
          if (gestante === "No" && lactancia === "Si" && criterioMel !== "Si") {
            setAlbendazolMel("Si");
            setAlbendazolDosisMel("Dosis única (400mg)");
            setMicronutMel("Si");
            setLnmsqMel("No");
            setPlumpyMel("No");
            setDosisMedicamentoMel("1 tab diaria por 100 días");
            setMedicamentosMel("Micronutrientes");
          }
        }
      }
    } else {
      if (diagFinalMel === "Peso adecuado") {
        if (gestante === "Si" && lactancia === "No" && criterioMel !== "Si") {
          setAlbendazolMel("Si");
          setAlbendazolDosisMel("Dosis única (400mg)");
          setMicronutMel("Si");
          setLnmsqMel("No");
          setPlumpyMel("No");
          setDosisMedicamentoMel("1 tab diaria por 100 días");
          setMedicamentosMel("Micronutrientes");
        } else {
          if (gestante === "No" && lactancia === "Si" && criterioMel !== "Si") {
            setAlbendazolMel("Si");
            setAlbendazolDosisMel("Dosis única (400mg)");
            setMicronutMel("Si");
            setLnmsqMel("No");
            setPlumpyMel("No");
            setDosisMedicamentoMel("1 tab diaria por 100 días");
            setMedicamentosMel("Micronutrientes");
          }
        }
      } else {
        if (diagFinalMel === "Sobrepeso") {
          if (gestante === "Si" && lactancia === "No" && criterioMel !== "Si") {
            setAlbendazolMel("Si");
            setAlbendazolDosisMel("Dosis única (400mg)");
            setMicronutMel("Si");
            setLnmsqMel("No");
            setPlumpyMel("No");
            setDosisMedicamentoMel("1 tab diaria por 100 días");
            setMedicamentosMel("Micronutrientes");
          } else {
            if (
              gestante === "No" &&
              lactancia === "Si" &&
              criterioMel !== "Si"
            ) {
              setAlbendazolMel("Si");
              setAlbendazolDosisMel("Dosis única (400mg)");
              setMicronutMel("Si");
              setLnmsqMel("No");
              setPlumpyMel("No");
              setDosisMedicamentoMel("1 tab diaria por 100 días");
              setMedicamentosMel("Micronutrientes");
            }
          }
        } else {
          if (diagFinalMel === "Obesidad") {
            if (
              gestante === "Si" &&
              lactancia === "No" &&
              criterioMel !== "Si"
            ) {
              setAlbendazolMel("Si");
              setAlbendazolDosisMel("Dosis única (400mg)");
              setMicronutMel("Si");
              setLnmsqMel("No");
              setPlumpyMel("No");
              setDosisMedicamentoMel("1 tab diaria por 100 días");
              setMedicamentosMel("Micronutrientes");
            } else {
              if (
                gestante === "No" &&
                lactancia === "Si" &&
                criterioMel !== "Si"
              ) {
                setAlbendazolMel("Si");
                setAlbendazolDosisMel("Dosis única (400mg)");
                setMicronutMel("Si");
                setLnmsqMel("No");
                setPlumpyMel("No");
                setDosisMedicamentoMel("1 tab diaria por 100 días");
                setMedicamentosMel("Micronutrientes");
              }
            }
          } else {
            if (diagFinalMel === "" && criterioMel !== "Si") {
              setAlbendazolMel("");
              setAlbendazolDosisMel("");
              setMicronutMel("");
              setLnmsqMel("");
              setPlumpyMel("");
              setDosisMedicamentoMel("");
              setMedicamentosMel("");
            }
          }
        }
      }
    }
  }, [diagFinalMel]);

  ////////////////////////////////////////////////////////////////////////////

  /*const handleImcIngreso = (event) => {
    setSelectImc(event.target.value);
    let index = imcIngreso.findIndex(
      (elemento) => elemento.value === event.target.value
    );
    if (index === 0) {
      setDiagIngresoMel("Bajo Peso");
    }
    if (index === 1) {
      setDiagIngresoMel("Peso Adecuado");
    }
    if (index === 2) {
      setDiagIngresoMel("Sobrepeso");
    }
    if (index === 3) {
      setDiagIngresoMel("Obesidad");
    }
    if (index < 0) {
      setDiagIngresoMel("");
    }
  };*/

  //Alerts Handlers
  const [openNoti, setOpenNoti] = React.useState(false);
  const [openNotiError, setOpenNotiError] = React.useState(false);

  /*const handleClickTop = () => {
    animateScroll.scrollTo(options);
  };*/

  var prueba = [];
  const [usuarios, setUsuarios] = useState([]);
  const getUsuarios = async () => {
    try {
      const { data2 } = await axios
        .get("/usuariostranscripcion")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          let datos = response.data;
          for (let i = 0; i < datos.length; i++) {
            datos[i].nombreCompleto =
              datos[i].nombres + " " + datos[i].apellidos;
          }
          setUsuarios(datos);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleSubmit = async (event) => {
  
    setDisableBtnM5(true);
    try {
      const { data1 } = await axios
        .post("crearbeneficiarioCinco/", {
          //idConvocatoria: params.idConvocatoria,
          codigo_proyecto: Number(proyect.idProyecto),
          id_convocatoria: proyect.id,
          estado: estadoM5,
          municipio: municipioM5,
          parroquia: parroquiaM5,
          centro: centroM5,
          comunidad: comunidadM5,
          grupo_etnico: grupoEtnicoM5,
          tipo_ingreso: tipoIngreso,
          cedula: cedulaRepM5,
          nombre_rep: representanteM5,
          telefono_rep: telfRepM5.textmask,
          nombre: nombreBenefM5,
          apellido: apellidoBenefM5,
          sexo: genero,
          fecha_nacimiento: fechaNac,
          fecha_evaluacion: fechaEval,
          edad: edadM5,
          registrado: registradoM5,
          escolaridad: escolarizadoM5,
          lactancia: valueRL,
          veces: vecesDiaM5,
          biberon: biberon,
          otros_alimentos: otros,
          agua_hervida: hervida,
          agua_filtrada: filtrada,
          agua_sintratar: sinTrat,
          gripe_tos: gripe,
          fiebre: fiebre,
          diarrea: diarrea,
          vomito: vomito,
          paludismo_malaria: paludismo,
          discapacidad: valueDisc,
          tipoDiscapacidad: cualM5,
          edema: edema,
          circunferencia: cmbM5,
          clasificacion_pb: pbM5,
          peso: pesoM5,
          talla: tallaM5,
          puntaje_z: puntajezM5,
          clasificacion_nut: clasificacionNutM5,
          diagnostico_ingreso: diagFinalM5,
          consejeria: consejM5,
          referido: referidoM5,
          albendazol: albendazolM5,
          micronutrientes: micronutM5,
          lns_mq: lnsmq,
          ruft: rutfM5,
          kitH:kitHigieneM5,
          kitR:kitRepoM5,
          amoxicilina: amoxM5,
          medicamentos: medicamentos,
          dAlbendazol: albendazolDosisM5,
          dMedicamentos: dosisMedicamentoM5,
          observaciones: observacionesM5,
          medico: inputValue,
          medicoId: medicoM5,
          estatus: 0,
          tipo: "NUT",
          transcrito: items.usuario,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setGrupoEtnicoM5("");
          setRepresentanteM5("");
          setTelfRepM5("");
          setCedulaRepM5("");
          setNombreBenefM5("");
          setApellidoBenefM5("");
          setGenero("");
          setFechaNac(null);
          setEdadM5("");
          setRegistradoM5("");
          setEscolarizadoM5("");
          setValueRL("");
          setVecesDiaM5("");
          setBiberon("");
          setOtros("");
          setHervida("");
          setFiltrada("");
          setSinTrat("");
          setGripe("");
          setFiebre("");
          setDiarrea("");
          setVomito("");
          setPaludismo("");
          setValueDisc("");
          setCualM5("");
          setEdema("No");
          setCmbM5("");
          setPbM5("");
          setPesoM5("");
          setTallaM5("");
          setPuntajezM5("");
          setClasificacionNutM5("");
          setDiagFinalM5("");
          setReferidoM5("No");
          setAlbendazolM5("");
          setMicronutM5("");
          setLnsmq("");
          setRutfM5("");
          setAmoxM5("");
          setAlbendazolDosisM5("");
          setDosisMedicamentoM5("");
          setObservacionesM5("");
          setDisableBtnM5(false);
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
          setDisableBtnM5(false);
        });
    } catch (error) {
      //console.log(error);
    }
    //handleOpenAlert();
  };

  const handleSubmitMel = async (event) => {
    setDisableBtnMel(true);
    try {
      const { data1 } = await axios
        .post("/crearbeneficiarioMel/", {
          //idConvocatoria: params.idConvocatoria,
          codigo_proyecto: Number(proyect.idProyecto),
          id_convocatoria: proyect.id,
          estado: estadoMel,
          municipio: municipioMel,
          parroquia: parroquiaMel,
          centro_atencion: centroMel,
          comunidad: comunidadMel,
          grupo_etnico: grupoEtnicoMel,
          tipo_ingreso: ingresoMel,
          cedula: cedulaMel,
          nombre: nombresMel,
          apellido: apellidosMel,
          telefono: telfMel.textmask,
          fecha_nacimiento: fechaNacMel,
          gestante: gestante,
          semana_gestacion: semanasGest,
          peso: pesoMel,
          talla: tallaMel,
          lactancia: lactancia,
          criterio: criterioMel,
          fecha_evaluacion: fechaEvalMel,
          edad: edadMel,
          circunferencia_brazo: cmbMel,
          clasificacion_pb: cpbMel,
          imc: selectImc,
          diagnostico_imc: diagIngresoMel,
          diagnostico_ingreso: diagFinalMel,
          diagnostico_gestacion: diagSemGestMel,
          consejeria: consejMel,
          referido: referidoMel,
          albendazol: albendazolMel,
          micronutrientes: micronutMel,
          lnsmq: lnsmqMel,
          plumpy: plumpyMel,
          kitH:kitHigieneMel,
          kitR:kitRepoMel,
          medicamentos: medicamentoMel,
          dAlbendazol: albendazolDosisMel,
          dMedicamentos: dosisMedicamentoMel,
          observaciones: observacionesMel,
          medico: inputValueMel,
          medicoId: medicoMel,
          estatus: 0,
          tipo: "NUT",
          transcrito: items.usuario,
          // //        nombres: (dataform.get('nombreProyecto')),
          // //        apellidos: (dataform.get('codigoProyecto')),
          // //        cedula: (dataform.get('descripcion')),
          // //        correo: dataform.get('email'),
          // //        telefono: dataform.get('textmask'),
          // //        //fnacimiento:new Date(selectedDate).toLocaleDateString('es-ES') ,
          // //        ocupacion: dataform.get('ocupacion'),
          // //        direccion: dataform.get('direccion'),
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setGrupoEtnicoMel("");
          setCedulaMel("");
          setNombresMel("");
          setApellidosMel("");
          setTelfMel("");
          setFechaNacMel(null);
          setGestante("");
          setLactancia("");
          setEdadMel("");
          setCmbMel("");
          setCpbMel("");
          setPesoMel("");
          setTallaMel("");
          setSemGestMel("");
          setSelectImc("");
          setDiagIngresoMel("");
          setDiagFinalMel("");
          setReferidoMel("No");
          setAlbendazolMel("");
          setMicronutMel("");
          setLnmsqMel("");
          setPlumpyMel("");
          setAlbendazolDosisMel("");
          setMedicamentosMel("");
          setObservacionesMel("");
          setDisableBtnMel(false);
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
          setDisableBtnMel(false);
        });
    } catch (error) {}

    //handleOpenAlert();
  };
  const handleSubmitDes = async (event) => {
    setDisableBtnDes(true);
    try {
      const { data1 } = await axios
        .post("/crearbeneficiarioDes/", {
          //idConvocatoria: params.idConvocatoria,
          codigo_proyecto: Number(proyect.idProyecto),
          id_convocatoria: proyect.id,
          fecha_inr: fechaInicDesp,
          fecha_finr: fechaFinalDesp,
          nombre_centro: nombreCentroDesp,
          parroquia: parroquiaDesp,
          municipio: municipioDesp,
          estado: estadoDesp,
          comunidad: comunidadDesp,
          ninos_desparacitados: ninosDesp,
          ninas_desparacitadas: ninasDesp,
          escolarizados: escolaridadDesp,
          equipo: equipo,
          estatus: 0,
          tipo: "NUT",
          transcrito: items.usuario,
          // //        nombres: (dataform.get('nombreProyecto')),
          // //        apellidos: (dataform.get('codigoProyecto')),
          // //        cedula: (dataform.get('descripcion')),
          // //        correo: dataform.get('email'),
          // //        telefono: dataform.get('textmask'),
          // //        //fnacimiento:new Date(selectedDate).toLocaleDateString('es-ES') ,
          // //        ocupacion: dataform.get('ocupacion'),
          // //        direccion: dataform.get('direccion'),
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setNinosDesp("");
          setNinasDesp("");
          setEscolaridadDesp("");
          setEquipo("");
          setDisableBtnDes(false);
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
          setDisableBtnDes(false);
        });
    } catch (error) {}

    //handleOpenAlert();
  };
  const handleSubmitCa = async (event) => {
    setDisableBtnCap(true);
    try {
      const { data1 } = await axios
        .post("/crearbeneficiarioCapacitacion/", {
          //idConvocatoria: params.idConvocatoria,
          codigo_proyecto: Number(proyect.idProyecto),
          id_convocatoria: proyect.id,
          fecha_inr: fechaInicCap,
          fecha_finr: fechaFinalCap,
          nombre_centro: nombreCentroCap,
          parroquia: parroquiaCap,
          municipio: municipioCap,
          estado: estadoCap,
          comunidad: comunidadCap,
          indigena: indigenaCap,
          mestizo: mestizoCap,
          sd: sinInformacionCap,
          hombres17: hombres017Cap,
          mujeres17: mujeres017Cap,
          hombres59: hombres1859Cap,
          mujeres59: mujeres1859Cap,
          hombres60: hombres60Cap,
          mujeres60: mujeres60Cap,
          total: totalCap,
          discapacitados: discapacitadosCap,
          total_lgbtqi: lgbtqiCap,
          equipo: equipoCap,
          estatus: 0,
          tipo: "NUT",
          transcrito: items.usuario,
          // //        nombres: (dataform.get('nombreProyecto')),
          // //        apellidos: (dataform.get('codigoProyecto')),
          // //        cedula: (dataform.get('descripcion')),
          // //        correo: dataform.get('email'),
          // //        telefono: dataform.get('textmask'),
          // //        //fnacimiento:new Date(selectedDate).toLocaleDateString('es-ES') ,
          // //        ocupacion: dataform.get('ocupacion'),
          // //        direccion: dataform.get('direccion'),
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setHombres017Cap("");
          setMujeres017Cap("");
          setHombres1859Cap("");
          setMujeres1859Cap("");
          setHombres60Cap("");
          setMujeres60Cap("");
          setIndigenaCap("");
          setMestizoCap("");
          setSinInformacionCap("");
          setDiscapacitadosCap("");
          setLgbtqiCap("");
          setEquipoCap("");
          setDisableBtnCap(false);
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
          setDisableBtnCap(false);
        });
    } catch (error) {}

    //handleOpenAlert();
  };

  if (ejecutar) {
    getUsuarios();
  }

  const [value, setValue] = React.useState(usuarios[0]);

  const [inputValue, setInputValue] = React.useState("");
  const [inputValueMel, setInputValueMel] = React.useState("");

  ////////////////  DIAGNÓSTICO Semanas Gestacion ///////////////
  useEffect(() => {
    if (gestante == "Si") {
      if (semanasGest == "6") {
        if (
          Number(selectImc) < 20 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20 &&
            Number(selectImc) <= 24.9 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25 &&
              Number(selectImc) <= 30 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////////
      if (semanasGest == "7") {
        if (
          Number(selectImc) < 20.1 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.1 &&
            Number(selectImc) <= 24.9 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25 &&
              Number(selectImc) <= 30 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semanasGest == "8") {
        if (
          Number(selectImc) < 20.2 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.2 &&
            Number(selectImc) <= 25 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.1 &&
              Number(selectImc) <= 30.1 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.1 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semanasGest == "9") {
        if (
          Number(selectImc) < 20.2 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.2 &&
            Number(selectImc) <= 25.1 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.2 &&
              Number(selectImc) <= 30.2 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.2 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "10") {
        if (
          Number(selectImc) < 20.3 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.3 &&
            Number(selectImc) <= 25.2 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.3 &&
              Number(selectImc) <= 30.2 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.2 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "11") {
        if (
          Number(selectImc) < 20.4 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.4 &&
            Number(selectImc) <= 25.3 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.4 &&
              Number(selectImc) <= 30.3 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.3 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "12") {
        if (
          Number(selectImc) < 20.5 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.5 &&
            Number(selectImc) <= 25.4 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.5 &&
              Number(selectImc) <= 30.3 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.3 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semanasGest == "13") {
        if (
          Number(selectImc) < 20.7 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.7 &&
            Number(selectImc) <= 25.6 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.7 &&
              Number(selectImc) <= 30.4 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.4 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semanasGest == "14") {
        if (
          Number(selectImc) < 20.8 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.8 &&
            Number(selectImc) <= 25.7 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.8 &&
              Number(selectImc) <= 30.5 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.5 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "15") {
        if (
          Number(selectImc) < 20.9 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 20.9 &&
            Number(selectImc) <= 25.8 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 25.9 &&
              Number(selectImc) <= 30.6 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.6 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////////
      if (semanasGest == "16") {
        if (
          Number(selectImc) < 21.1 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.1 &&
            Number(selectImc) <= 25.9 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26 &&
              Number(selectImc) <= 30.7 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.7 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////////
      if (semanasGest == "17") {
        if (
          Number(selectImc) < 21.2 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.2 &&
            Number(selectImc) <= 26 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.1 &&
              Number(selectImc) <= 30.8 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.8 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semanasGest == "18") {
        if (
          Number(selectImc) < 21.3 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.3 &&
            Number(selectImc) <= 26.1 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.2 &&
              Number(selectImc) <= 30.9 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.9 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "19") {
        if (
          Number(selectImc) < 21.5 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.5 &&
            Number(selectImc) <= 26.2 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.3 &&
              Number(selectImc) <= 30.9 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 30.9 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "20") {
        if (
          Number(selectImc) < 21.6 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.6 &&
            Number(selectImc) <= 26.3 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.4 &&
              Number(selectImc) <= 31 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ////////////////////////
      if (semanasGest == "21") {
        if (
          Number(selectImc) < 21.8 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.8 &&
            Number(selectImc) <= 26.4 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.5 &&
              Number(selectImc) <= 31.1 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.1 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "22") {
        if (
          Number(selectImc) < 21.9 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 21.9 &&
            Number(selectImc) <= 26.6 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.7 &&
              Number(selectImc) <= 31.2 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.2 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semanasGest == "23") {
        if (
          Number(selectImc) < 22.1 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 22.1 &&
            Number(selectImc) <= 26.7 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 26.8 &&
              Number(selectImc) <= 31.3 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.3 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "24") {
        if (
          Number(selectImc) < 22.3 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 22.3 &&
            Number(selectImc) <= 26.9 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27 &&
              Number(selectImc) <= 31.5 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.5 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "25") {
        if (
          Number(selectImc) < 22.5 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 22.5 &&
            Number(selectImc) <= 27 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27.1 &&
              Number(selectImc) <= 31.6 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.6 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ////////////////////////
      if (semanasGest == "26") {
        if (
          Number(selectImc) < 22.7 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 22.7 &&
            Number(selectImc) <= 27.2 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27.2 &&
              Number(selectImc) <= 31.7 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.7 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "27") {
        if (
          Number(selectImc) < 22.8 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 22.8 &&
            Number(selectImc) <= 27.3 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27.4 &&
              Number(selectImc) <= 31.8 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.8 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "28") {
        if (
          Number(selectImc) < 23 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 23 &&
            Number(selectImc) <= 27.5 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27.6 &&
              Number(selectImc) <= 31.9 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 31.9 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "29") {
        if (
          Number(selectImc) < 23.2 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 23.2 &&
            Number(selectImc) <= 27.6 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27.7 &&
              Number(selectImc) <= 32 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "30") {
        if (
          Number(selectImc) < 23.4 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 23.4 &&
            Number(selectImc) <= 27.8 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 27.9 &&
              Number(selectImc) <= 32.1 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.1 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "31") {
        if (
          Number(selectImc) < 23.5 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 23.5 &&
            Number(selectImc) <= 27.9 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28 &&
              Number(selectImc) <= 32.2 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.2 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "32") {
        if (
          Number(selectImc) < 23.7 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 23.7 &&
            Number(selectImc) <= 28 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.1 &&
              Number(selectImc) <= 32.3 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.3 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "33") {
        if (
          Number(selectImc) < 23.9 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 23.9 &&
            Number(selectImc) <= 28.1 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.2 &&
              Number(selectImc) <= 32.4 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.4 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "34") {
        if (
          Number(selectImc) < 24 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 24 &&
            Number(selectImc) <= 28.3 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.4 &&
              Number(selectImc) <= 32.5 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.5 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "35") {
        if (
          Number(selectImc) < 24.2 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 24.2 &&
            Number(selectImc) <= 28.4 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.5 &&
              Number(selectImc) <= 32.6 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.6 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "36") {
        if (
          Number(selectImc) < 24.3 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 24.3 &&
            Number(selectImc) <= 28.5 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.6 &&
              Number(selectImc) <= 32.7 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.7 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "37") {
        if (
          Number(selectImc) < 24.5 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 24.5 &&
            Number(selectImc) <= 28.7 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.8 &&
              Number(selectImc) <= 32.8 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.7 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "38") {
        if (
          Number(selectImc) < 24.6 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 24.6 &&
            Number(selectImc) <= 28.8 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 28.9 &&
              Number(selectImc) <= 32.9 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 32.9 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "39") {
        if (
          Number(selectImc) < 24.8 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 24.8 &&
            Number(selectImc) <= 28.9 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 29 &&
              Number(selectImc) <= 33 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 33 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semanasGest == "40") {
        if (
          Number(selectImc) < 25 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 25 &&
            Number(selectImc) <= 29.1 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 29.2 &&
              Number(selectImc) <= 33.1 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 33.1 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////////
      if (semanasGest == "41") {
        if (
          Number(selectImc) < 25.1 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          console.log("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 25.1 &&
            Number(selectImc) <= 29.2 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            console.log("Adecuado");
          } else {
            if (
              Number(selectImc) >= 29.3 &&
              Number(selectImc) <= 33.2 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              console.log("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 33.2 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                console.log("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semanasGest == "42") {
        if (
          Number(selectImc) < 25.1 &&
          selectImc !== "" &&
          criterioMel !== "Si"
        ) {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (
            Number(selectImc) >= 25.1 &&
            Number(selectImc) <= 29.2 &&
            selectImc !== "" &&
            criterioMel !== "Si"
          ) {
            setDiagSemGestMel("Adecuado");
          } else {
            if (
              Number(selectImc) >= 29.3 &&
              Number(selectImc) <= 33.2 &&
              selectImc !== "" &&
              criterioMel !== "Si"
            ) {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (
                Number(selectImc) > 33.2 &&
                selectImc !== "" &&
                criterioMel !== "Si"
              ) {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
    }
  }, [selectImc]);
  const handleFilterId = (nombre) => {
    console.log(nombre);
  };

  // Toalizacion Capacitados
  let totalCap =
    parseInt(hombres017Cap) +
    parseInt(mujeres017Cap) +
    parseInt(hombres1859Cap) +
    parseInt(mujeres1859Cap) +
    parseInt(hombres60Cap) +
    parseInt(mujeres60Cap);
  let capTotal = totalCap || "";

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "8" || rol === "11" ? (
        <>
          <Collapse in={openNoti}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNoti(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Datos guardados exitosamente!
            </Alert>
          </Collapse>
          <Collapse in={openNotiError}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNotiError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Error al guardar datos!
            </Alert>
          </Collapse>
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
            ref={topRef}
          >
            <CssBaseline />
            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/transcripcion"
                  onClick={(event) =>
                    handleLinkTranscripcion(event, "transcripcion")
                  }
                >
                  <DriveFileRenameOutlineIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                  Transcripción
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/listaTranscripcion"
                  onClick={(event) => handleLinkConvos(event, "convos")}
                >
                  <AssignmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Convocatoria
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <NoteAddOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Nuevo Beneficiario (NUT)
                </Typography>
              </Breadcrumbs>
              <Typography
                variant="h3"
                component="div"
                sx={{ mt: 3, mb: 1, fontWeight: "bold" }}
              >
                Nuevo Beneficiario (NUT)
              </Typography>
            </Box>
            <Container>
              <Card
                sx={{
                  borderRadius: 8,
                  boxShadow: 5,
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={valueTabs}
                        variant={isSmallScreen ? "scrollable" : "fullWidth"}
                        onChange={handleChangeTabs}
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="Nutrición Tabs"
                      >
                        <Tab
                          icon={<ChildCareOutlinedIcon />}
                          iconPosition="start"
                          label="Menores de 5 Años"
                          {...a11yProps(0)}
                        />
                        <Tab
                          icon={<PregnantWomanOutlinedIcon />}
                          iconPosition="start"
                          label="MEL"
                          {...a11yProps(1)}
                        />
                        <Tab
                          icon={<MedicationOutlinedIcon />}
                          iconPosition="start"
                          label="Desparasitaciones"
                          {...a11yProps(2)}
                          hidden={proyect.actividad == "Control Nutricional"}
                        />
                        <Tab
                          icon={<SchoolIcon />}
                          iconPosition="start"
                          label="Capacitaciones"
                          {...a11yProps(3)}
                          hidden={proyect.actividad == "Control Nutricional"}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={valueTabs} index={0}>
                      <Box
                        component="form"
                        noValidate
                        /*onSubmit={handleSubmit}*/ sx={{ mt: 1 }}
                      >
                        <Box>
                          <Divider sx={{ mt: 1, mb: 3 }}>
                            <Chip label="INFO. GENERAL" />
                          </Divider>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="estado"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="estado"
                                label="Estado"
                                value={estadoM5}
                                onChange={(event) =>
                                  setEstadoM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="municipio"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="municipio"
                                label="Municipio"
                                value={municipioM5}
                                onChange={(event) =>
                                  setMunicipioM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="parroquia"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="parroquia"
                                label="Parroquia"
                                value={parroquiaM5}
                                onChange={(event) =>
                                  setParroquiaM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="centroSalud"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="centroSalud"
                                label="Nombre del Centro de Atención en Salud"
                                value={centroM5}
                                onChange={(event) =>
                                  setCentroM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="comunidad"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="comunidad"
                                label="Comunidad"
                                value={comunidadM5}
                                onChange={(event) =>
                                  setComunidadM5(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="DATOS DEL BENEFICIARIO" />
                          </Divider>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                              <FormControl fullWidth>
                                <InputLabel id="grupoEtnicoM5">
                                  Grupo Étnico
                                </InputLabel>
                                <Select
                                  value={grupoEtnicoM5}
                                  label="Grupo Étnico"
                                  onChange={handleGrupoEtnicoM5}
                                >
                                  <MenuItem value={"Indígena"}>
                                    Indígena
                                  </MenuItem>
                                  <MenuItem value={"Afrodescendiente"}>
                                    Afrodescendiente
                                  </MenuItem>
                                  <MenuItem value={"Mestizo"}>Mestizo</MenuItem>
                                  <MenuItem value={"S/D"}>S/D</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Nombre del Representante"
                                fullWidth
                                required
                                value={representanteM5}
                                onChange={(event) =>
                                  setRepresentanteM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              {/*<TextField
                                label="Teléfono del Representante"
                                fullWidth
                                value={telfRepM5}
                                onChange={(event) =>
                                  setTelfRepM5(event.target.value)
                                }
                              />*/}
                              <FormControl fullWidth>
                                <InputLabel
                                  htmlFor="telefono"
                                  variant="outlined"
                                >
                                  Teléfono
                                </InputLabel>
                                <OutlinedInput
                                  value={telfRepM5.textmask}
                                  onChange={handleTelfRepM5}
                                  name="textmask"
                                  id="telefono"
                                  inputComponent={TextMaskCustom}
                                  label="Teléfono"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Cédula del Representante"
                                fullWidth
                                value={cedulaRepM5}
                                onChange={(event) =>
                                  setCedulaRepM5(
                                    event.target.value
                                      .replace(/[^\d]/g, "")
                                      .slice(0, 10)
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Nombres del Beneficiario"
                                fullWidth
                                required
                                value={nombreBenefM5}
                                onChange={(event) =>
                                  setNombreBenefM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Apellidos del Beneficiario"
                                fullWidth
                                required
                                value={apellidoBenefM5}
                                onChange={(event) =>
                                  setApellidoBenefM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <FormControl fullWidth>
                                <InputLabel id="genero">Género</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={genero}
                                  label="Género"
                                  onChange={handleChangeGenero}
                                >
                                  <MenuItem value={"Femenino"}>
                                    Femenino
                                  </MenuItem>
                                  <MenuItem value={"Masculino"}>
                                    Masculino
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                              >
                                <DesktopDatePicker
                                  label="Fecha de Evaluación"
                                  name="fechaEvaluacion"
                                  inputFormat="DD/MM/YYYY"
                                  disabled={
                                    proyect.actividad != "Control Nutricional"
                                  }
                                  value={fechaEval}
                                  onChange={setFechaEval}
                                  renderInput={(params) => {
                                    return <TextField fullWidth {...params} />;
                                  }}
                                  clearable
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                              >
                                <DesktopDatePicker
                                  label="Fecha de Nacimiento"
                                  name="fechaNacimiento"
                                  inputFormat="DD/MM/YYYY"
                                  value={fechaNac}
                                  onChange={setFechaNac}
                                  renderInput={(params) => {
                                    return <TextField fullWidth {...params} />;
                                  }}
                                  clearable
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Edad en Meses"
                                fullWidth
                                required
                                disabled
                                value={edadM5}
                                onChange={(event) =>
                                  setEdadM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <FormControl fullWidth>
                                <InputLabel id="registradoM5">
                                  Registrado
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={registradoM5}
                                  label="Resgistrado"
                                  onChange={handleRegistrado}
                                >
                                  <MenuItem value={"Si"}>Si</MenuItem>
                                  <MenuItem value={"No"}>No</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <FormControl fullWidth>
                                <InputLabel id="escolarizadoM5">
                                  Escolaridad
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={escolarizadoM5}
                                  label="Escolaridad"
                                  onChange={handleEscolarizado}
                                >
                                  <MenuItem value={"Etapa Inicial"}>
                                    Etapa Inicial
                                  </MenuItem>
                                  <MenuItem value={"Prescolar"}>
                                    Prescolar
                                  </MenuItem>
                                  <MenuItem value={"Escolar"}>Escolar</MenuItem>
                                  <MenuItem value={"Sin escolaridad"}>
                                    Sin escolaridad
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4 }}>
                            <Chip label="LACTANCIA" />
                          </Divider>
                          <Grid container mt={2}>
                            <Grid item xs={12} sm={3} mt={2}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  ¿Recibe Lactancia Materna?
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={valueRL}
                                  onChange={handleChangeRL}
                                  row
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={2}>
                              <TextField
                                label="¿Cúantas veces al día?"
                                disabled={valueRL !== "Si"}
                                value={vecesDiaM5}
                                onChange={(event) =>
                                  setVecesDiaM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3} mt={2}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  ¿Usa Biberón?
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={biberon}
                                  onChange={handleChangeBiberon}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={2}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Otros Alimentos
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={otros}
                                  onChange={handleChangeOtros}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Agua Hervida
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={hervida}
                                  onChange={handleChangeHervida}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Agua Filtrada o Clorada
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={filtrada}
                                  onChange={handleChangeFiltrada}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Agua sin Tratamiento
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={sinTrat}
                                  onChange={handleChangeSinTrat}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Gripe o Tos en la Última Semana
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={gripe}
                                  onChange={handleChangeGripe}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Fiebre en la Última Semana
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={fiebre}
                                  onChange={handleChangeFiebre}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Diarrea en la Última Semana
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={diarrea}
                                  onChange={handleChangeDiarrea}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Vómito
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={vomito}
                                  onChange={handleChangeVomito}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Paludismo o Malaria
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={paludismo}
                                  onChange={handleChangePaludismo}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Discapacidad
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  value={valueDisc}
                                  onChange={handleChangeDisc}
                                  row
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <TextField
                                label="¿Cúal?"
                                disabled={valueDisc !== "Si"}
                                value={cualM5}
                                onChange={(event) =>
                                  setCualM5(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="INFO. NUTRICIONAL" />
                          </Divider>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={2}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Edema
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={edema}
                                  onChange={handleChangeEdema}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <TextField
                                label="Circ. de Brazo (CMB)"
                                fullWidth
                                value={cmbM5}
                                onChange={handleCmbM5}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Clasificación PB"
                                fullWidth
                                value={pbM5}
                                disabled
                                /*onChange={(event) =>
                                  setPbM5(event.target.value)
                                }*/
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <TextField
                                label="Peso (Kg)"
                                fullWidth
                                required
                                value={pesoM5}
                                onChange={(event) =>
                                  setPesoM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <TextField
                                label="Talla (cm)"
                                fullWidth
                                required
                                value={tallaM5}
                                onChange={(event) =>
                                  setTallaM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Puntaje Z"
                                fullWidth
                                value={puntajezM5}
                                onChange={handlePuntajeZ}
                              />
                              {/*<FormControl fullWidth>
                                <InputLabel id="puntajezM5">
                                  Puntaje Z
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={puntajezM5}
                                  label="Puntaje Z"
                                  onChange={handlePuntajeZ}
                                >
                                  <MenuItem value="">
                                    <em>Seleccionar Puntaje Z...</em>
                                  </MenuItem>
                                  <MenuItem value={"< -3"}>{"< -3"}</MenuItem>
                                  <MenuItem value={"≥-3 a < -2"}>
                                    {"≥-3 a < -2"}
                                  </MenuItem>
                                  <MenuItem value={"≥-2 a < -1"}>
                                    {"≥-2 a < -1"}
                                  </MenuItem>
                                  <MenuItem value={"≥-1 a ≤ 1"}>
                                    {"≥-1 a ≤ 1"}
                                  </MenuItem>
                                  <MenuItem value={"> 1 a ≤ 2"}>
                                    {"> 1 a ≤ 2"}
                                  </MenuItem>
                                  <MenuItem value={"> 2 a ≤ 3"}>
                                    {"> 2 a ≤ 3"}
                                  </MenuItem>
                                  <MenuItem value={"> 3"}>{"> 3"}</MenuItem>
                                </Select>
                              </FormControl>*/}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Clasificación Nutricional"
                                fullWidth
                                value={clasificacionNutM5}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextField
                                label="Diagnóstico de Ingreso"
                                fullWidth
                                value={diagFinalM5}
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4 }}>
                            <Chip label="TRATAMIENTO" />
                          </Divider>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Consejería en Prácticas Clave
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={consejM5}
                                  onChange={handleChangeConsejM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Referido
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={referidoM5}
                                  onChange={handleReferidoM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Albendazol
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={albendazolM5}
                                  onChange={handleChangeAlbendazolM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={referidoM5 === "Si"}
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={referidoM5 === "Si"}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Micronutrientes
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={
                                    lnsmq === "Si" || rutfM5 === "Si"
                                      ? "No"
                                      : micronutM5
                                  }
                                  onChange={handleChangeMicronutM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={
                                      lnsmq === "Si" ||
                                      rutfM5 === "Si" ||
                                      referidoM5 === "Si"
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={
                                      lnsmq === "Si" ||
                                      rutfM5 === "Si" ||
                                      referidoM5 === "Si"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  LNS-MQ (Sobre Amarillo)
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={
                                    micronutM5 === "Si" || rutfM5 === "Si"
                                      ? "No"
                                      : lnsmq
                                  }
                                  onChange={handleChangeLnsmq}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={
                                      micronutM5 === "Si" ||
                                      rutfM5 === "Si" ||
                                      referidoM5 === "Si"
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={
                                      micronutM5 === "Si" ||
                                      rutfM5 === "Si" ||
                                      referidoM5 === "Si"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  RUTF (Sobre Rojo)
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={
                                    micronutM5 === "Si" || lnsmq === "Si"
                                      ? "No"
                                      : rutfM5
                                  }
                                  onChange={handleChangeRutfM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={
                                      micronutM5 === "Si" ||
                                      lnsmq === "Si" ||
                                      referidoM5 === "Si"
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={
                                      micronutM5 === "Si" ||
                                      lnsmq === "Si" ||
                                      referidoM5 === "Si"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              mt={3}
                              hidden={
                                diagFinalM5 !== "Desnutrición Aguda Severa"
                              }
                            >
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Amoxicilina
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={amoxM5}
                                  onChange={handleChangeAmoxM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3} /*hidden={proyect.actividad != "Control Nutricional"}*/>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Kit de Higiene
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={kitHigieneM5}
                                  onChange={handleKitHigieneM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} mt={3} /*hidden={proyect.actividad != "Control Nutricional"}*/>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Kit de Reposición
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={kitRepoM5}
                                  onChange={handleKitRepoM5}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="DOSIS" />
                          </Divider>
                          <Typography
                            variant="h5"
                            className="text-center"
                            mt={4}
                            hidden={
                              albendazolM5 === "Si" ||
                              micronutM5 === "Si" ||
                              lnsmq === "Si" ||
                              rutfM5 === "Si"
                            }
                            sx={{ color: "gray" }}
                          >
                            <InfoOutlinedIcon sx={{ color: "gray" }} /> No se
                            asignaron tratamientos al beneficiario.
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={albendazolM5 !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <FormControl fullWidth>
                                <InputLabel id="albendazolDosisM5">
                                  Albendazol
                                </InputLabel>
                                <Select
                                  value={albendazolDosisM5}
                                  label="Albendazol"
                                  onChange={handleAlbendazolDosisM5}
                                >
                                  <MenuItem value="">
                                    <em>Seleccionar Dosis...</em>
                                  </MenuItem>
                                  <MenuItem value={"Dosis única (200mg)"}>
                                    Dosis única (200mg)
                                  </MenuItem>
                                  <MenuItem value={"Dosis única (400mg)"}>
                                    Dosis única (400mg)
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={micronutM5 !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label="Micronutrientes"
                                value={dosisMedicamentoM5}
                                onChange={(event) =>
                                  setDosisMedicamentoM5(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={lnsmq !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label="LNS-MQ (Sobre Amarillo)"
                                value={dosisMedicamentoM5}
                                onChange={(event) =>
                                  setDosisMedicamentoM5(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={rutfM5 !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label="RUTF (Sobre Rojo)"
                                value={dosisMedicamentoM5}
                                onChange={(event) =>
                                  setDosisMedicamentoM5(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="ADICIONALES" />
                          </Divider>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Observaciones"
                                fullWidth
                                multiline
                                maxRows={4}
                                value={observacionesM5}
                                onChange={(event) =>
                                  setObservacionesM5(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                value={value}
                                onChange={(event, newValue) => {
                                  setMedicoM5(newValue.id);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                  setInputValue(newInputValue);
                                  handleFilterId(newInputValue);
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={usuarios}
                                getOptionLabel={(usuarios) =>
                                  usuarios.nombreCompleto
                                }
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Médico Tratante"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 5 }}></Divider>
                          <Grid
                            container
                            sx={{ mt: 2 }}
                            spacing={1}
                            justifyContent="center"
                          >
                            <Grid item sm={3} xs={6}>
                              <Button
                                variant="contained"
                                color="error"
                                size="large"
                                fullWidth
                                onClick={handleOpen}
                              >
                                Cancelar
                              </Button>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <Button
                                variant="contained"
                                sx={{ bgcolor: "teal", color: "white" }}
                                ref={buttRef}
                                onClick={handleSubmit}
                                disabled={!diagFinalM5 || disableBtnM5 == true}
                                size="large"
                                fullWidth
                              >
                                Guardar
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={1}>
                      <Box
                        component="form"
                        noValidate
                        /*onSubmit={handleSubmit}*/ sx={{ mt: 1 }}
                      >
                        <Box>
                          <Divider sx={{ mt: 1, mb: 3 }}>
                            <Chip label="INFO. GENERAL" />
                          </Divider>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="estado"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="estado"
                                label="Estado"
                                value={estadoMel}
                                onChange={(event) =>
                                  setEstadoMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="municipio"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="municipio"
                                label="Municipio"
                                value={municipioMel}
                                onChange={(event) =>
                                  setMunicipioMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="parroquia"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="parroquia"
                                label="Parroquia"
                                value={parroquiaMel}
                                onChange={(event) =>
                                  setParroquiaMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="centroSalud"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="centroSalud"
                                label="Nombre del Centro de Atención en Salud"
                                value={centroMel}
                                onChange={(event) =>
                                  setCentroMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="comunidad"
                                required
                                fullWidth
                                disabled={
                                  proyect.actividad != "Control Nutricional"
                                }
                                id="comunidad"
                                label="Comunidad"
                                value={comunidadMel}
                                onChange={(event) =>
                                  setComunidadMel(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="DATOS DEL BENEFICIARIO" />
                          </Divider>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <FormControl fullWidth>
                                <InputLabel id="grupoEtnicoMel">
                                  Grupo Étnico
                                </InputLabel>
                                <Select
                                  value={grupoEtnicoMel}
                                  label="Grupo Étnico"
                                  onChange={handleGrupoEtnicoMel}
                                >
                                  <MenuItem value={"Indígena"}>
                                    Indígena
                                  </MenuItem>
                                  <MenuItem value={"Afrodescendiente"}>
                                    Afrodescendiente
                                  </MenuItem>
                                  <MenuItem value={"Mestizo"}>Mestizo</MenuItem>
                                  <MenuItem value={"S/D"}>S/D</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label="Cédula de Identidad"
                                fullWidth
                                value={cedulaMel}
                                onChange={(event) =>
                                  setCedulaMel(
                                    event.target.value
                                      .replace(/[^\d]/g, "")
                                      .slice(0, 10)
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label="Nombres"
                                fullWidth
                                required
                                value={nombresMel}
                                onChange={(event) =>
                                  setNombresMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label="Apellidos"
                                fullWidth
                                required
                                value={apellidosMel}
                                onChange={(event) =>
                                  setApellidosMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl fullWidth>
                                <InputLabel
                                  htmlFor="telefono"
                                  variant="outlined"
                                >
                                  Teléfono
                                </InputLabel>
                                <OutlinedInput
                                  value={telfMel.textmask}
                                  onChange={handleTelfMel}
                                  name="textmask"
                                  id="telefono"
                                  inputComponent={TextMaskCustom}
                                  label="Teléfono"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                              >
                                <DesktopDatePicker
                                  label="Fecha de Nacimiento"
                                  name="fechaNacimiento"
                                  inputFormat="DD/MM/YYYY"
                                  value={fechaNacMel}
                                  onChange={setFechaNacMel}
                                  renderInput={(params) => {
                                    return <TextField fullWidth {...params} />;
                                  }}
                                  clearable
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FormControl fullWidth>
                                <InputLabel id="gestante">Gestante</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={gestante}
                                  label="Gestante"
                                  onChange={handleGestante}
                                >
                                  <MenuItem value={"Si"}>Si</MenuItem>
                                  <MenuItem value={"No"}>No</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} sm={4}>
                              <TextField
                                label="Semanas de Gestación"
                                fullWidth
                                disabled={gestante !== "Si"}
                                value={semGestMel}
                                onChange={(event) =>
                                  setSemGestMel(event.target.value)
                                }
                              />
                              </Grid>*/}
                            <Grid item xs={12} sm={4}>
                              <FormControl fullWidth>
                                <InputLabel id="lactancia">
                                  Lactancia
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={lactancia}
                                  label="Lactancia"
                                  disabled
                                  onChange={handleLactancia}
                                >
                                  <MenuItem value={"Si"}>Si</MenuItem>
                                  <MenuItem value={"No"}>No</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                              >
                                <DesktopDatePicker
                                  label="Fecha de Valoración"
                                  name="fechaEvaluacion"
                                  inputFormat="DD/MM/YYYY"
                                  disabled={
                                    proyect.actividad != "Control Nutricional"
                                  }
                                  value={fechaEvalMel}
                                  onChange={setFechaEvalMel}
                                  renderInput={(params) => {
                                    return <TextField fullWidth {...params} />;
                                  }}
                                  clearable
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label="Edad en Años"
                                fullWidth
                                value={edadMel}
                                disabled
                                // onChange={handleEdadMel}
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="INFO. NUTRICIONAL" />
                          </Divider>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              sm={2}
                              hidden={gestante !== "Si"}
                            >
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Criterio Médico
                                </FormLabel>
                                <RadioGroup
                                  row
                                  value={criterioMel}
                                  onChange={handleCriterioMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={gestante !== "Si" ? 3 : 2}>
                              <TextField
                                label="Talla (cm)"
                                type="number"
                                value={tallaMel}
                                onChange={handleTallaMel}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={gestante !== "Si" ? 3 : 2}>
                              <TextField
                                label="Peso (Kg)"
                                type="number"
                                value={pesoMel}
                                onChange={handlePesoMel}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={gestante !== "Si" ? 3 : 3}>
                              <TextField
                                label="Circunferencia de Brazo (CMB)"
                                fullWidth
                                value={cmbMel}
                                onChange={handleCmbMel}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Clasificación PB"
                                fullWidth
                                value={cpbMel}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={gestante !== "Si" ? 3 : 2}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Semanas de Gestación
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={semanasGest}
                                  disabled={
                                    gestante !== "Si" || lactancia !== "No"
                                  }
                                  label="Semanas de Gestación"
                                  onChange={handleSemanasGest}
                                >
                                  <MenuItem value="">
                                    <em>Seleccionar Semana de Gestación...</em>
                                  </MenuItem>
                                  <MenuItem value="6">6</MenuItem>
                                  <MenuItem value="7">7</MenuItem>
                                  <MenuItem value="8">8</MenuItem>
                                  <MenuItem value="9">9</MenuItem>
                                  <MenuItem value="10">10</MenuItem>
                                  <MenuItem value="11">11</MenuItem>
                                  <MenuItem value="12">12</MenuItem>
                                  <MenuItem value="13">13</MenuItem>
                                  <MenuItem value="14">14</MenuItem>
                                  <MenuItem value="15">15</MenuItem>
                                  <MenuItem value="16">16</MenuItem>
                                  <MenuItem value="17">17</MenuItem>
                                  <MenuItem value="18">18</MenuItem>
                                  <MenuItem value="19">19</MenuItem>
                                  <MenuItem value="20">20</MenuItem>
                                  <MenuItem value="21">21</MenuItem>
                                  <MenuItem value="22">22</MenuItem>
                                  <MenuItem value="23">23</MenuItem>
                                  <MenuItem value="24">24</MenuItem>
                                  <MenuItem value="25">25</MenuItem>
                                  <MenuItem value="26">26</MenuItem>
                                  <MenuItem value="27">27</MenuItem>
                                  <MenuItem value="28">28</MenuItem>
                                  <MenuItem value="29">29</MenuItem>
                                  <MenuItem value="30">30</MenuItem>
                                  <MenuItem value="31">31</MenuItem>
                                  <MenuItem value="32">32</MenuItem>
                                  <MenuItem value="33">33</MenuItem>
                                  <MenuItem value="34">34</MenuItem>
                                  <MenuItem value="35">35</MenuItem>
                                  <MenuItem value="36">36</MenuItem>
                                  <MenuItem value="37">37</MenuItem>
                                  <MenuItem value="38">38</MenuItem>
                                  <MenuItem value="39">39</MenuItem>
                                  <MenuItem value="40">40</MenuItem>
                                  <MenuItem value="41">41</MenuItem>
                                  <MenuItem value="42">42</MenuItem>
                                  <MenuItem value="No">No</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={gestante !== "Si" ? 3 : 2}>
                              <TextField
                                label="IMC de Ingreso"
                                fullWidth
                                value={selectImc}
                                onChange={handleImcIngreso}
                              />
                              {/*<FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  IMC de Ingreso
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectImc}
                                  disabled={gestante !== "Si"}
                                  label="IMC de Ingreso"
                                  onChange={handleImcIngreso}
                                >
                                  <MenuItem value="">
                                    <em>Seleccionar IMC...</em>
                                  </MenuItem>
                                  {imcIngreso.map((dat, index) => (
                                    <MenuItem value={dat.value}>
                                      {dat.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>*/}
                            </Grid>
                            <Grid item xs={12} sm={gestante !== "Si" ? 3 : 2}>
                              <TextField
                                label="Diagnóstico IMC"
                                fullWidth
                                value={diagIngresoMel}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                label="Diagnóstico de Ingreso"
                                fullWidth
                                value={diagFinalMel}
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              hidden={gestante !== "Si"}
                            >
                              <TextField
                                label="Diagnóstico según Semanas de Gestación"
                                fullWidth
                                value={diagSemGestMel}
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4 }}>
                            <Chip label="TRATAMIENTO" />
                          </Divider>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={4} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Consejería en Lactancia Materna y Alimentación
                                  del Niño Pequeño
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={consejMel}
                                  onChange={handleConsejMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Referido
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={referidoMel}
                                  onChange={handleReferidoMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Albendazol
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={albendazolMel}
                                  onChange={handleAlbendazolMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={referidoMel === "Si"}
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={referidoMel === "Si"}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Micronutrientes
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={
                                    plumpyMel === "Si" ? "No" : micronutMel
                                  }
                                  onChange={handleMicronutMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={
                                      lnsmqMel === "Si" ||
                                      plumpyMel === "Si" ||
                                      referidoMel === "Si"
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={
                                      lnsmqMel === "Si" ||
                                      plumpyMel === "Si" ||
                                      referidoMel === "Si"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  LNS-MQ (Sobre Amarillo)
                                </FormLabel>
                                <RadioGroup
                                  name="radio-buttons-group"
                                  row
                                  value={
                                    micronutMel === "Si" || plumpyMel === "Si"
                                      ? "No"
                                      : lnsmqMel
                                  }
                                  onChange={handleLnsmqMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={
                                      micronutMel === "Si" ||
                                      plumpyMel === "Si" ||
                                      referidoMel === "Si"
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={
                                      micronutMel === "Si" ||
                                      plumpyMel === "Si" ||
                                      referidoMel === "Si"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Plumpy'Mum
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={
                                    micronutMel === "Si" || lnsmqMel === "Si"
                                      ? "No"
                                      : plumpyMel
                                  }
                                  onChange={handlePlumpyMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                    disabled={
                                      micronutMel === "Si" ||
                                      lnsmqMel === "Si" ||
                                      referidoMel === "Si"
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    disabled={
                                      micronutMel === "Si" ||
                                      lnsmqMel === "Si" ||
                                      referidoMel === "Si"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3} hidden={proyect.actividad != "Control Nutricional"}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Kit de Higiene
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={kitHigieneMel}
                                  onChange={handleKitHigieneMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} mt={3} hidden={proyect.actividad != "Control Nutricional"}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  Kit de Reposición
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  row
                                  value={kitRepoMel}
                                  onChange={handleKitRepoMel}
                                >
                                  <FormControlLabel
                                    value="Si"
                                    control={<Radio />}
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="DOSIS" />
                          </Divider>
                          <Typography
                            variant="h5"
                            className="text-center"
                            mt={4}
                            hidden={
                              albendazolMel === "Si" ||
                              micronutMel === "Si" ||
                              plumpyMel === "Si" ||
                              lnsmqMel === "Si"
                            }
                            sx={{ color: "gray" }}
                          >
                            <InfoOutlinedIcon sx={{ color: "gray" }} /> No se
                            asignaron tratamientos al beneficiario.
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={albendazolMel !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <FormControl fullWidth>
                                <InputLabel id="albendazolDosisMel">
                                  Albendazol
                                </InputLabel>
                                <Select
                                  value={albendazolDosisMel}
                                  label="Albendazol"
                                  onChange={handleAlbendazolDosisMel}
                                >
                                  <MenuItem value="">
                                    <em>Seleccionar Dosis...</em>
                                  </MenuItem>
                                  <MenuItem value={"Dosis única (200mg)"}>
                                    Dosis única (200mg)
                                  </MenuItem>
                                  <MenuItem value={"Dosis única (400mg)"}>
                                    Dosis única (400mg)
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={micronutMel !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label="Micronutrientes"
                                value={dosisMedicamentoMel}
                                onChange={(event) =>
                                  setDosisMedicamentoMel(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={plumpyMel !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label="Plumpy'Mum"
                                value={dosisMedicamentoMel}
                                onChange={(event) =>
                                  setDosisMedicamentoMel(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                            hidden={lnsmqMel !== "Si"}
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label="LNS-MQ (Sobre Amarillo)"
                                value={dosisMedicamentoMel}
                                onChange={(event) =>
                                  setDosisMedicamentoMel(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 4, mb: 3 }}>
                            <Chip label="ADICIONALES" />
                          </Divider>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={6}>
                              <TextField
                                label="Observaciones"
                                fullWidth
                                multiline
                                maxRows={4}
                                value={observacionesMel}
                                onChange={(event) =>
                                  setObservacionesMel(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                value={value}
                                onChange={(event, newValue) => {
                                  setMedicoMel(newValue.id);
                                }}
                                inputValue={inputValueMel}
                                onInputChange={(event, newInputValue) => {
                                  setInputValueMel(newInputValue);
                                  handleFilterId(newInputValue);
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={usuarios}
                                getOptionLabel={(usuarios) =>
                                  usuarios.nombreCompleto
                                }
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Médico Tratante"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 5 }}></Divider>
                          <Grid
                            container
                            sx={{ mt: 2 }}
                            spacing={1}
                            justifyContent="center"
                          >
                            <Grid item sm={3} xs={6}>
                              <Button
                                variant="contained"
                                color="error"
                                size="large"
                                fullWidth
                                onClick={handleOpen}
                              >
                                Cancelar
                              </Button>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <Button
                                variant="contained"
                                sx={{ bgcolor: "teal", color: "white" }}
                                onClick={handleSubmitMel}
                                disabled={
                                  !diagFinalMel || disableBtnMel == true
                                }
                                size="large"
                                fullWidth
                              >
                                Guardar
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={2}>
                      <Box
                        component="form"
                        noValidate
                        /*onSubmit={handleSubmit}*/ sx={{ mt: 1 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="es"
                            >
                              <DesktopDatePicker
                                label="Fecha Inicio del reporte"
                                name="fechaEvaluacion"
                                disabled
                                inputFormat="DD/MM/YYYY"
                                value={fechaInicDesp}
                                onChange={setFechaInicDesp}
                                renderInput={(params) => {
                                  return <TextField fullWidth {...params} />;
                                }}
                                clearable
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="es"
                            >
                              <DesktopDatePicker
                                label="Fecha Final del reporte"
                                name="fechaEvaluacion"
                                disabled
                                inputFormat="DD/MM/YYYY"
                                value={fechaFinalDesp}
                                onChange={setFechaFinalDesp}
                                renderInput={(params) => {
                                  return <TextField fullWidth {...params} />;
                                }}
                                clearable
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Nombre del Centro de Salud"
                              fullWidth
                              disabled
                              value={nombreCentroDesp}
                              onChange={(event) =>
                                setNombreCentroDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Parroquia"
                              fullWidth
                              disabled
                              value={parroquiaDesp}
                              onChange={(event) =>
                                setParroquiaDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Municipio"
                              fullWidth
                              disabled
                              value={municipioDesp}
                              onChange={(event) =>
                                setMunicipioDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Estado"
                              fullWidth
                              disabled
                              value={estadoDesp}
                              onChange={(event) =>
                                setEstadoDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Comunidad"
                              fullWidth
                              disabled
                              value={comunidadDesp}
                              onChange={(event) =>
                                setComunidadDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Total de Niños Desparasitados"
                              fullWidth
                              value={ninosDesp}
                              onChange={(event) =>
                                setNinosDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Total de Niñas Desparasitados"
                              fullWidth
                              value={ninasDesp}
                              onChange={(event) =>
                                setNinasDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Total Escolarizados"
                              type="number"
                              fullWidth
                              value={escolaridadDesp}
                              onChange={(event) =>
                                setEscolaridadDesp(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="equipoDesp">Equipo</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={equipo}
                                label="Equipo"
                                onChange={handleEquipo}
                              >
                                <MenuItem value={"ALFA"}>ALFA</MenuItem>
                                <MenuItem value={"BETA"}>BETA</MenuItem>
                                <MenuItem value={"DELTA"}>DELTA</MenuItem>
                                <MenuItem value={"GAMMA"}>GAMMA</MenuItem>
                                <MenuItem value={"OMEGA"}>OMEGA</MenuItem>
                                <MenuItem value={"ZETA"}>ZETA</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 5 }}></Divider>
                        <Grid
                          container
                          sx={{ mt: 2 }}
                          spacing={1}
                          justifyContent="center"
                        >
                          <Grid item sm={3} xs={6}>
                            <Button
                              variant="contained"
                              color="error"
                              size="large"
                              fullWidth
                              onClick={handleOpen}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <Button
                              onClick={handleSubmitDes}
                              variant="contained"
                              sx={{ bgcolor: "teal", color: "white" }}
                              /*disabled={!valueName || !valueCodigo || !valueFI || !valueFF || !soc || sectorOpc=="" || !valueDescripcion || corP=="" || corLo=="" || corSum=="" || corMe=="" }*/ size="large"
                              disabled={disableBtnDes == true}
                              fullWidth
                            >
                              Guardar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={3}>
                      <Box
                        component="form"
                        noValidate
                        /*onSubmit={handleSubmit}*/ sx={{ mt: 1 }}
                      >
                        <Divider sx={{ mt: 1, mb: 3 }}>
                          <Chip label="INFO. GENERAL" />
                        </Divider>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="es"
                            >
                              <DesktopDatePicker
                                label="Fecha Inicio del reporte"
                                disabled
                                name="fechaEvaluacion"
                                inputFormat="DD/MM/YYYY"
                                value={fechaInicCap}
                                onChange={setFechaInicCap}
                                renderInput={(params) => {
                                  return <TextField fullWidth {...params} />;
                                }}
                                clearable
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="es"
                            >
                              <DesktopDatePicker
                                label="Fecha Final del reporte"
                                name="fechaEvaluacion"
                                disabled
                                inputFormat="DD/MM/YYYY"
                                value={fechaFinalCap}
                                onChange={setFechaFinalCap}
                                renderInput={(params) => {
                                  return <TextField fullWidth {...params} />;
                                }}
                                clearable
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Nombre del Centro de Salud"
                              fullWidth
                              disabled
                              value={nombreCentroCap}
                              onChange={(event) =>
                                setNombreCentroCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Parroquia"
                              fullWidth
                              disabled
                              value={parroquiaCap}
                              onChange={(event) =>
                                setParroquiaCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Municipio"
                              fullWidth
                              disabled
                              value={municipioCap}
                              onChange={(event) =>
                                setMunicipioCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Estado"
                              fullWidth
                              disabled
                              value={estadoCap}
                              onChange={(event) =>
                                setEstadoCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Comunidad"
                              fullWidth
                              disabled
                              value={comunidadCap}
                              onChange={(event) =>
                                setComunidadCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Latitud"
                              fullWidth
                              disabled
                              value={latitudCap}
                              onChange={(event) =>
                                setLatitudCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Longitud"
                              fullWidth
                              disabled
                              value={longitudCap}
                              onChange={(event) =>
                                setLongitudCap(event.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                        {/*
                        <Divider sx={{ mt: 3, mb: 3 }}>
                          <Chip label="GRUPO ETNICO" />
                        </Divider>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                              label="Indígena"
                              type="number"
                              value={indigenaCap}
                              name="indigenaCap"
                              onChange={(event) =>
                                setIndigenaCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Mestizo"
                              type="number"
                              value={mestizoCap}
                              name="mestizoCap"
                              onChange={(event) =>
                                setMestizoCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="S/D"
                              type="number"
                              value={sdCap}
                              name="sdCap"
                              onChange={(event) =>
                                setSdCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                            </Grid>*/}
                        <Divider sx={{ mt: 3, mb: 3 }}>
                          <Chip label="TOTALIZACIÓN" />
                        </Divider>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Hombres de 0 a 17 años"
                              type="number"
                              fullWidth
                              value={hombres017Cap}
                              name="hombres017Cap"
                              onChange={(event) =>
                                setHombres017Cap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Mujeres de 0 a 17 años"
                              type="number"
                              fullWidth
                              value={mujeres017Cap}
                              name="mujeres017Cap"
                              onChange={(event) =>
                                setMujeres017Cap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Hombres de 18 a 59 años"
                              type="number"
                              fullWidth
                              value={hombres1859Cap}
                              name="hombres1859Cap"
                              onChange={(event) =>
                                setHombres1859Cap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Mujeres de 18 a 59 años"
                              type="number"
                              fullWidth
                              value={mujeres1859Cap}
                              name="mujeres1859Cap"
                              onChange={(event) =>
                                setMujeres1859Cap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Hombres de 60 años o más"
                              type="number"
                              fullWidth
                              value={hombres60Cap}
                              name="hombres60Cap"
                              onChange={(event) =>
                                setHombres60Cap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Mujeres de 60 años o más"
                              type="number"
                              fullWidth
                              value={mujeres60Cap}
                              name="mujeres60Cap"
                              onChange={(event) =>
                                setMujeres60Cap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Typography
                              className="text-center"
                              variant="h5"
                              mt={2}
                            >
                              <b>Total de Capacitados: </b> {capTotal}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 3, mb: 3 }}>
                          <Chip label="GRUPO ETNICO" />
                        </Divider>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Indígenas"
                              type="number"
                              value={indigenaCap}
                              name="indigenaCap"
                              onChange={(event) =>
                                setIndigenaCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Mestizos"
                              type="number"
                              value={mestizoCap}
                              name="mestizoCap"
                              onChange={(event) =>
                                setMestizoCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Sin Información"
                              type="number"
                              value={sinInformacionCap}
                              name="sinInformacionCap"
                              onChange={(event) =>
                                setSinInformacionCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 3, mb: 3 }}>
                          <Chip label="DATOS ADICIONALES" />
                        </Divider>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Discapacitados"
                              type="number"
                              value={discapacitadosCap}
                              name="discapacitadosCap"
                              onChange={(event) =>
                                setDiscapacitadosCap(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Personas LGBTQI+"
                              type="number"
                              fullWidth
                              value={lgbtqiCap}
                              onChange={(event) =>
                                setLgbtqiCap(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="equipoDesp">Equipo</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={equipoCap}
                                label="Equipo"
                                onChange={handleEquipoCap}
                              >
                                <MenuItem value={"ALFA"}>ALFA</MenuItem>
                                <MenuItem value={"BETA"}>BETA</MenuItem>
                                <MenuItem value={"DELTA"}>DELTA</MenuItem>
                                <MenuItem value={"GAMMA"}>GAMMA</MenuItem>
                                <MenuItem value={"OMEGA"}>OMEGA</MenuItem>
                                <MenuItem value={"ZETA"}>ZETA</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: 5 }}></Divider>
                        <Grid
                          container
                          sx={{ mt: 2 }}
                          spacing={1}
                          justifyContent="center"
                        >
                          <Grid item sm={3} xs={6}>
                            <Button
                              variant="contained"
                              color="error"
                              size="large"
                              fullWidth
                              onClick={handleOpen}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <Button
                              onClick={handleSubmitCa}
                              variant="contained"
                              sx={{ bgcolor: "teal", color: "white" }}
                              /*disabled={!valueName || !valueCodigo || !valueFI || !valueFF || !soc || sectorOpc=="" || !valueDescripcion || corP=="" || corLo=="" || corSum=="" || corMe=="" }*/ size="large"
                              disabled={disableBtnCap == true}
                              fullWidth
                            >
                              Guardar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomTabPanel>
                  </Box>
                </CardContent>
              </Card>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={style}>
                  <Typography
                    variant="h6"
                    className="text-center"
                    sx={{ mb: 1.5 }}
                  >
                    ¿Está seguro de cancelar esta operación?
                  </Typography>
                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 1 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        onClick={(event) =>
                          handleLinkCancelar(event, "cancelar")
                        }
                      >
                        Si
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Container>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default NutriForm;
