import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import HomeIcon from "@mui/icons-material/Home";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import Typography from "@mui/material/Typography/Typography";
import Avatar from "@mui/material/Avatar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import StatBoxAdmin from "components/StatBoxAdmin";
import Restricted from "components/Restricted";
import LineGraph from "components/Stats/LineGraph";
import BarGraph from "components/Stats/BarGraph";
import PieGraph from "components/Stats/PieGraph";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import WcIcon from "@mui/icons-material/Wc";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import MedicationIcon from "@mui/icons-material/Medication";
import SanitizerIcon from "@mui/icons-material/Sanitizer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Map } from "@mui/icons-material";

const EstadisticasGenerales = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyect = JSON.parse(token);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [ninos, setNinos] = React.useState([]);
  const [mel, setMel] = React.useState([]);
  const [desp, setDesp] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [fecha, setFecha] = React.useState("2024-01-01");
  const [fechaFin, setFechaFin] = React.useState("2024-12-31");
  const beneficiariosNutNinos = async () => {
    try {
      const { data2 } = await axios
        .get("/beneficiariosninosfechas/", {
          params: {
            fechaInicio: fecha,
            fechaFin: fechaFin,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setNinos(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log(error, "error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosNutMel = async () => {
    try {
      const { data2 } = await axios
        .get("/beneficiariosnutmelfechas/", {
          params: {
            fechaInicio: fecha,
            fechaFin: fechaFin,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setMel(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log(error, "error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosNutDesparasitaciones = async () => {
    try {
      const { data2 } = await axios
        .get("/beneficiariosnutdesparasitacionesfechas/", {
          params: {
            fechaInicio: fecha,
            fechaFin: fechaFin,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDesp(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log(error, "error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);
  useEffect(() => {
    if (fecha == "2022-01-01") {
      setFechaFin("2022-12-31");
      setEjecutar(true);
    } else {
      if (fecha == "2023-01-01") {
        setFechaFin("2023-12-31");
        setEjecutar(true);
      } else {
        setFechaFin("2024-12-31");
        setEjecutar(true);
      }
    }
  }, [fecha]);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const nav = useNavigate();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Sector Select
  const [sector, setSector] = React.useState("");
  const handleSector = (event) => {
    setSector(event.target.value);
  };

  const [categoriaSalud, setCategoriaSalud] = React.useState("Grupo Etário");
  const handleCategoriaSalud = (event) => {
    setCategoriaSalud(event.target.value);
  };

  const [categoriaNutri, setCategoriaNutri] = React.useState("Menores");
  const handleCategoriaNutri = (event) => {
    setCategoriaNutri(event.target.value);
  };

  const handleFecha = (event) => {
    setFecha(event.target.value);
  };
  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkProjects = (event, message) => {
    if (message === "proyectos") {
      nav("/proyectos");
    }
  };

  const handleLinkMap = (event, message) => {
    if (message === "map") {
      nav("/map");
    }
  };

  if (ejecutar) {
    beneficiariosNutNinos();
    beneficiariosNutMel();
    beneficiariosNutDesparasitaciones();
  }

  if (mel.length < 1 || ninos.length < 1 || desp.length < 1)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" ||
      rol === "2" ||
      rol === "6" ||
      rol === "7" ||
      rol === "10" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/proyectos"
                onClick={(event) => handleLinkProjects(event, "proyectos")}
              >
                <CasesRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Proyectos
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <LeaderboardRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Estadísticas Generales
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ mt: 2 }}>
              <b>Estadísticas Generales</b>
            </Typography>
          </Box>
          <Grid container spacing={2} mt={3}>
            <Grid xs={12} sm={3}>
              <Box sx={{ minWidth: 120, mb: 3 }} fullwidth>
                <FormControl
                  sx={{
                    width: isSmallScreen ? "100%" : "95%",
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sector}
                    label="Sector"
                    onChange={handleSector}
                  >
                    <MenuItem value="">
                      <em>Seleccionar Sector...</em>
                    </MenuItem>
                    <MenuItem value={"Salud"}>Salud</MenuItem>
                    <MenuItem value={"Nutrición"}>Nutrición</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid xs={12} sm={2} hidden={sector !== "Salud"}>
              <Box sx={{ minWidth: 120, mb: 3 }} fullwidth>
                <FormControl
                  sx={{
                    width: isSmallScreen ? "100%" : "95%",
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Gráficas
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoriaSalud}
                    label="Gráficas"
                    onChange={handleCategoriaSalud}
                  >
                    <MenuItem disabled value="">
                      <em>Seleccionar Gráficas...</em>
                    </MenuItem>
                    <MenuItem value={"Grupo Etário"}>Grupo Etário</MenuItem>
                    <MenuItem value={"Género"}>Género</MenuItem>
                    <MenuItem value={"Discapacidad"}>Discapacidad</MenuItem>
                    <MenuItem value={"Municipios"}>Municipios</MenuItem>
                    <MenuItem value={"Patología"}>Patología</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid xs={12} sm={2} hidden={sector !== "Nutrición"}>
              <Box sx={{ minWidth: 120, mb: 3 }} fullwidth>
                <FormControl
                  sx={{
                    width: isSmallScreen ? "100%" : "95%",
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Gráficas
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoriaNutri}
                    label="Gráficas"
                    onChange={handleCategoriaNutri}
                  >
                    <MenuItem disabled value="">
                      <em>Seleccionar Gráficas ...</em>
                    </MenuItem>
                    <MenuItem value={"Menores"}>Menores de 5 Años</MenuItem>
                    <MenuItem value={"MEL"}>MEL</MenuItem>
                    <MenuItem value={"Desparasitaciones"}>
                      Desparasitaciones
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid xs={12} sm={2} hidden={sector !== "Nutrición"}>
              <Box sx={{ minWidth: 120, mb: 3 }} fullwidth>
                <FormControl
                  sx={{
                    width: isSmallScreen ? "100%" : "95%",
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Año</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={fecha}
                    label="Gráficas"
                    onChange={handleFecha}
                  >
                    <MenuItem disabled value="">
                      <em>Seleccionar Año ...</em>
                    </MenuItem>
                    <MenuItem value={"2022-01-01"}>2022</MenuItem>
                    <MenuItem value={"2023-01-01"}>2023</MenuItem>
                    <MenuItem value={"2024-01-01"}>2024</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={2}
              ml={2}
              hidden={
                sector !== "Nutrición" ||
                fecha !== "2024-01-01" ||
                isSmallScreen
              }
            >
              <Avatar
                sx={{ width: 55, height: 55, bgcolor: "teal", boxShadow: 4 }}
              >
                <Tooltip title="Ver Mapa de Calor">
                  <IconButton onClick={(event) => handleLinkMap(event, "map")}>
                    <Map sx={{ color: "white", fontSize: 30 }} />
                  </IconButton>
                </Tooltip>
              </Avatar>
            </Grid>
            <Grid
              xs={12}
              sm={2}
              mb={3}
              hidden={
                sector !== "Nutrición" ||
                fecha !== "2024-01-01" ||
                !isSmallScreen
              }
            >
              <Button
                variant="contained"
                size="large"
                sx={{ bgcolor: "teal", color: "white" }}
                fullWidth
                onClick={(event) => handleLinkMap(event, "map")}
              >
                <Map sx={{ mr: 1 }} /> Ver Mapa de Calor
              </Button>
            </Grid>
          </Grid>
          <Box mt="10px" hidden={sector === "Salud" || sector === "Nutrición"}>
            <Container>
              <Card sx={{ boxShadow: 0, bgcolor: "transparent" }}>
                <CardContent>
                  <Typography className="text-center">
                    <InfoOutlinedIcon
                      sx={{
                        fontWeight: "bold",
                        mt: 5,
                        fontSize: 150,
                        color: "GrayText",
                      }}
                    />
                  </Typography>
                  <Typography
                    variant={isSmallScreen ? "h4" : "h3"}
                    className="text-center"
                    sx={{ mt: 5, mb: 5, color: "GrayText" }}
                  >
                    Selecciona un Sector para Visualizar sus Estadísticas.
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </Box>
          <Box
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(8, 1fr)"
            gridAutoRows="160px"
            gap="20px"
            hidden={sector !== "Salud" && sector !== "Nutrición"}
            sx={{
              "& > div": {
                gridColumn: isNonMediumScreens ? undefined : "span 12",
              },
            }}
          >
            {/* ROW 1 */}
            <StatBoxAdmin
              title={
                sector === "Salud"
                  ? "Pacientes Atendidos"
                  : sector === "Nutrición"
                  ? "NNAS Atendidos"
                  : "Cargando..."
              }
              value={
                sector === "Salud"
                  ? 12345
                  : sector === "Nutrición"
                  ? ninos.total
                  : "Cargando..."
              }
              icon={
                sector === "Salud" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <Diversity1Icon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : sector === "Nutrición" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <ChildCareIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{ width: 75, height: 75, bgcolor: "teal" }}
                  ></Avatar>
                )
              }
              /*increase={
                sector === "Salud" ? 1234 : sector === "Nutrición" ? 4321 : 0
              }*/
            />
            <StatBoxAdmin
              title={
                sector === "Salud"
                  ? "NNAS Atendidos"
                  : sector === "Nutrición"
                  ? "MEL Atendidas"
                  : "Cargando..."
              }
              value={
                sector === "Salud"
                  ? 12345
                  : sector === "Nutrición"
                  ? mel.total
                  : "Cargando..."
              }
              icon={
                sector === "Salud" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <ChildCareIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : sector === "Nutrición" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <PregnantWomanIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{ width: 75, height: 75, bgcolor: "teal" }}
                  ></Avatar>
                )
              }
              /*increase={
                sector === "Salud" ? 1234 : sector === "Nutrición" ? 4321 : 0
              }*/
            />
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor={theme.palette.background.alt}
              p="1rem"
              borderRadius="1.5rem"
              boxShadow={5}
            >
              <LineGraph fecha={fecha} categoria={categoriaNutri} />
            </Box>
            <StatBoxAdmin
              title={
                sector === "Salud"
                  ? "Adultos Atendidos"
                  : sector === "Nutrición"
                  ? "Desparasitaciones"
                  : "Cargando..."
              }
              value={
                sector === "Salud"
                  ? 12345
                  : sector === "Nutrición"
                  ? desp.total
                  : "Cargando..."
              }
              icon={
                sector === "Salud" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <WcIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : sector === "Nutrición" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <MedicationIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{ width: 75, height: 75, bgcolor: "teal" }}
                  ></Avatar>
                )
              }
              /*increase={
                sector === "Salud" ? 1234 : sector === "Nutrición" ? 4321 : 0
              }*/
            />
            <StatBoxAdmin
              title={
                sector === "Salud"
                  ? "Medicamentos Entregados"
                  : sector === "Nutrición"
                  ? "Kits de Higiene Entregados a Familias "
                  : "Cargando..."
              }
              value={
                sector === "Salud"
                  ? 12345
                  : sector === "Nutrición" && fecha === "2023-01-01"
                  ? 720
                  : sector === "Nutrición" && fecha === "2024-01-01"
                  ? 235
                  : "Cargando..."
              }
              icon={
                sector === "Salud" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <MedicalServicesIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : sector === "Nutrición" ? (
                  <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
                    <SanitizerIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{ width: 75, height: 75, bgcolor: "teal" }}
                  ></Avatar>
                )
              }
              increase={
                sector === "Salud" ? 1234 : sector === "Nutrición" ? 60 : 0
              }
            />
            {/* ROW 2 */}
            <Box
              gridColumn="span 5"
              gridRow="span 2"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              height="auto"
              boxShadow={5}
              mt={5}
              hidden={fecha === "2024-01-01"}
            >
              <BarGraph fecha={fecha} categoria={categoriaNutri} />
            </Box>
            <Box
              gridColumn="span 5"
              gridRow="span 2"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              height="auto"
              boxShadow={5}
              mt={5}
              hidden={fecha !== "2024-01-01"}
            >
              <Typography className="text-center" variant="h3" mt={10}>
                <InfoOutlinedIcon sx={{ fontSize: 45 }} /> Gráfica disponible
                próximamente.
              </Typography>
            </Box>
            <Box
              gridColumn="span 3"
              gridRow="span 2"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              height="auto"
              boxShadow={5}
              mt={5}
            >
              <PieGraph fecha={fecha} categoria={categoriaNutri} />
            </Box>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default EstadisticasGenerales;
