import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UserIcon from "@mui/icons-material/Person";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import VoluntList from "components/VoluntList";
import Collapse from "@mui/material/Collapse";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Voluntariado = () => {
  const nav = useNavigate();
  const [ejecutar, setEjecutar] = React.useState(true);
  const [tablaAc, setTablaAc] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [dataF, setDataF] = React.useState([]);
  const [aceptada, setAceptada] = React.useState([]);
  const [cancelada, setCancelada] = React.useState([]);
  const [espera, setEspera] = React.useState([]);
  const [rechazada, setRechazada] = React.useState([]);
  const [propias, setPropias] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [seleccionadas, setSeleccionadas] = React.useState([]);
  const [equipos, setEquipos] = React.useState([]);
  const [refrigerios, setRefrigerios] = React.useState('');
  const [datosGuardar, setDatosGuardar] = React.useState({});
  const dayjs = require("dayjs");
  var customParseFormat = require("dayjs/plugin/customParseFormat");

  const hoy = dayjs().format("YYYY-MM-DD HH:mm");
console.log(hoy);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Tabs
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const convocatorias = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 1,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setEjecutar(false);
          let datos = response.data;

          for (let i = 0; i < datos.length; i++) {
            for (let f = 0; f < datos[i].bases.length; f++) {
              if (datos[i].bases[f] == items.idOcupacion) {
                datos[i].ident = true;
              }
            }
          }
          for (let i = 0; i < datos.length; i++) {
            let equipos = JSON.parse(datos[i].equipos);
            for (let f = 0; f < equipos.length; f++) {
              if (equipos[f] == items.equipo) {
                datos[i].identE = true;
              }
            }
          }
          console.log(datos,"Pruebaasas");
          setData(datos);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const convocatoriasFinalizada = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 2,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setEjecutar(false);
          let datos = response.data;

          for (let i = 0; i < datos.length; i++) {
            for (let f = 0; f < datos[i].bases.length; f++) {
              if (datos[i].bases[f] == items.idOcupacion) {
                datos[i].ident = true;
              }
            }
          }
          console.log(datos);
          setDataF(datos);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const handleConfirmar = async (dato,refri) => {
    console.log(dato);
console.log(refri)
    try {
      const { data1 } = await axios
        .put("/aceptarconvocatoriaPrueba", {
          id: dato.id,
          id_logro:6,
          id_voluntario:items.id,
          profesion: items.idOcupacion,
          email: items.usuario,
          refrigerio:refri
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response,"Prueba1");
          handleCloseRefrigerios()
          if (response.data) {
            handleOpenAlertWaiting();
          } else {
            handleOpenAlertConfirm();
          }

          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
        })
        .catch(function (error) {
          console.log(error);
          handleOpenAlerterrorConfirm();
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {}
  };
  const handleActualizarRefrigerio = async (dato,refri) => {
    console.log(dato);

    try {
      const { data1 } = await axios
        .put("/actualizarRefrigerio", {
          id: dato.id,
          profesion: items.idOcupacion,
          email: items.usuario,
          refrigerio:refri
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          handleCloseRefrigerios()
         

          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
        })
        .catch(function (error) {
          console.log(error.response.data);
          handleOpenAlerterrorConfirm();
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {}
  };

  const handleRechazar = async (dato) => {
    console.log(dato);

    try {
      const { data1 } = await axios
        .put("/rechazarconvocatoria", {
          id: dato.original.id,
          profesion: items.idOcupacion,
          email: items.usuario,
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          handleOpenAlertReject();
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
        })
        .catch(function (error) {
          console.log("error");
          handleOpenAlerterrorReject();
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {}
  };

  const cancelar = async (dato) => {
    console.log(dato.original);
    console.log(mFalta);
    try {
      const { data1 } = await axios
        .put("/cancelarconvocatoria", {
          id: dato.original.id,
          profesion: items.idOcupacion,
          email: items.usuario,
          motivo: mFalta,
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)

          setOpenMF(false);
          setEjecutar(true);
        })
        .catch(function (error) {
          setEjecutar(true);
          console.log("error");
          // document.getElementById(":r7:").value='';
        });

      //setOpen(false);
    } catch (error) {
      handleCloseMF();
      handleOpenAlertCancel();
      setEjecutar(true);
    }
  };

  const handleCompletar = async (dato) => {
    console.log(dato);
    console.log(valueComentarioFinal);
    try {
      const { data1 } = await axios
        .put("/completarconvocatoria", {
          id: dato.id,
          comentarioFinal: valueComentarioFinal,
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          handleOpenAlertComplete();
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
          handleClose();
        })
        .catch(function (error) {
          console.log(error.response.data);
          handleOpenAlerterrorConfirm();
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {}
  };
  const handleEliminar = async (dato) => {
  console.log(datos,"eliminar")
    try {
      const { data1 } = await axios
        .put("/eliminarconvocatoria", {
          id: dato.id,
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          handleOpenAlertComplete();
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
          handleClose();
        })
        .catch(function (error) {
          console.log(error.response.data);
          handleOpenAlerterrorConfirm();
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {}
  };
  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  const styleSug = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "30%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  const styleVol = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "50%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  const styleRefri = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "30%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };

  //Modals
  const [open, setOpen] = React.useState(false);
  const [tabla, setTabla] = React.useState(false);
  const [datos, setDatos] = React.useState({});
  const [datosCompletar, setDatosCompletar] = React.useState({});
  const handleOpen = (row) => {
    setDatosCompletar(row.original);

    setAnchorEl(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (row) => {
    aceptadas(row.original.id);
    canceladas(row.original.id);
    esperas(row.original.id);
    rechazadas(row.original.id);
    console.log("prueba");

    setAnchorEl(null);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const [openMF, setOpenMF] = React.useState(false);
  const [datosCancelar, setDatosCancelar] = React.useState("");
  const handleOpenMF = (row) => {
    setDatosCancelar(row);
    setAnchorEl(null);
    setOpenMF(true);
  };
  const handleCloseMF = () => setOpenMF(false);

  const [openAlertConfirm, setOpenAlertConfirm] = React.useState(false);
  const handleOpenAlertConfirm = () => {
    setOpenAlertConfirm(true);
    handleCloseAlertConfirm();
  };
  const handleCloseAlertConfirm = () =>
    setTimeout(() => {
      setOpenAlertConfirm(false);
    }, 2000);

  const [openAlertCancel, setOpenAlertCancel] = React.useState(false);
  const handleOpenAlertCancel = () => {
    setOpenAlertCancel(true);
    handleCloseAlertCancel();
  };
  const handleCloseAlertCancel = () =>
    setTimeout(() => {
      setOpenAlertCancel(false);
    }, 2000);

  const [openAlertReject, setOpenAlertReject] = React.useState(false);
  const handleOpenAlertReject = () => {
    setOpenAlertReject(true);
    handleCloseAlertReject();
  };
  const handleCloseAlertReject = () =>
    setTimeout(() => {
      setOpenAlertReject(false);
    }, 2000);

  const [openAlertComplete, setOpenAlertComplete] = React.useState(false);
  const handleOpenAlertComplete = () => {
    setOpenAlertComplete(true);
    handleCloseAlertComplete();
  };
  const handleCloseAlertComplete = () =>
    setTimeout(() => {
      setOpenAlertComplete(false);
    }, 2000);

  const [openAlertErrorCorfirm, setOpenAlertErrorConfirm] =
    React.useState(false);
  const handleOpenAlerterrorConfirm = () => {
    setOpenAlertErrorConfirm(true);
    handleCloseAlertErrorConfirm();
  };
  const handleCloseAlertErrorConfirm = () =>
    setTimeout(() => {
      setOpenAlertErrorConfirm(false);
    }, 2000);

  const [openAlertWaiting, setOpenAlertWaiting] = React.useState(false);
  const handleOpenAlertWaiting = () => {
    setOpenAlertWaiting(true);
    handleCloseAlertWaiting();
  };
  const handleCloseAlertWaiting = () =>
    setTimeout(() => {
      setOpenAlertWaiting(false);
    }, 2000);

  const [openAlertSugerencia, setOpenAlertSugerencia] = React.useState(false);
  const handleOpenAlertSugerencia = () => {
    setOpenAlertSugerencia(true);
    handleCloseAlertSugerencia();
  };
  const handleCloseAlertSugerencia = () =>
    setTimeout(() => {
      setOpenAlertSugerencia(false);
    }, 2000);

  const [openAlertErrorReject, setOpenAlertErrorReject] = React.useState(false);
  const handleOpenAlerterrorReject = () => {
    setOpenAlertErrorReject(true);
    handleCloseAlertErrorReject();
  };
  const handleCloseAlertErrorReject = () =>
    setTimeout(() => {
      setOpenAlertErrorReject(false);
    }, 2000);

  const [openSugerencia, setOpenSugerencia] = React.useState(false);
  const handleSugerencia = () => setOpenSugerencia(true);
  const handleCloseSugerencia = () => {
    setOpenSugerencia(false);
    setSugerencia("");
    setCheck(false);
  };

  const [openRefrigerios, setOpenRefrigerios] = React.useState(false);
  const handleOpenRefrigerios = (dato,tipo) =>{
setRefrigerios(tipo)
setDatosGuardar(dato.original)
setOpenRefrigerios(true);

  }
  const handleCloseRefrigerios = () => setOpenRefrigerios(false);
  const handleRefrigerios = (event, message,dato) => {
    let refri;
    if(refrigerios==="crear"){
      if(message=="No"){
        refri=0;
        handleConfirmar(dato,refri);
      
      }else{
        refri=1;
        handleConfirmar(dato,refri);
        
      }
    }else{
      if(message=="No"){
        refri=0;
        handleActualizarRefrigerio(dato,refri);
        setRejectRefri(true);
        handleCloseRefrigerios();
        setTimeout(() => {
          setRejectRefri(false);
        }, 2000);
      }else{
        refri=1;
        handleActualizarRefrigerio(dato,refri);
        setAlertRefri(true);
        handleCloseRefrigerios();
        setTimeout(() => {
          setAlertRefri(false);
        }, 2000);
      }
    }
  };

  //Alertas Refrigerio
  const [alertRefri, setAlertRefri] = React.useState(false);
  const handleAlertRefriOpen = (event) => {
    setAlertRefri(true);
    handleCloseRefrigerios();
    setTimeout(() => {
      setAlertRefri(false);
    }, 2000);
  };

  const [rejectRefri, setRejectRefri] = React.useState(false);
  const handleRejectRefri = (event) => {
    setRejectRefri(true);
    handleCloseRefrigerios();
    setTimeout(() => {
      setRejectRefri(false);
    }, 2000);
  };

  //DropDown Button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropDown = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  //Routes
  const handleLinkClick = (event, message) => {
    if (message === "convocatorias") {
      nav("/crearConvocatoria");
    }
  };
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkEditar = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "editar") {
      nav(`/editarConvocatoria?prop=${pro}`);
    }
  };

  //Form Submit
  const [mFalta, setFalta] = React.useState("");
  const [valueComentarioFinal, setComentarioFinal] = React.useState("");

  const handleFalta = (event) => {
    setFalta(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(mFalta);
    setFalta("");
  };

  const [sugerencia, setSugerencia] = React.useState("");

  const handleFormSugerencia = (event) => {
    setSugerencia(event.target.value);
  };

  const [stateAnonimo, setCheck] = React.useState({
    anonimo: false,
  });

  const handleChangeCheck = (event) => {
    setCheck({
      ...stateAnonimo,
      [event.target.name]: event.target.checked,
    });
  };

  const { anonimo } = stateAnonimo;

  const handleSubmitSugerencias = async (event) => {
    event.preventDefault();
    var correo = "Anónimo";
    var nombreS = "Anónimo";
    if (!anonimo) {
      correo = items.usuario;
      nombreS = items.nombre + " " + items.apellido;
    }

    try {
      const { data1 } = await axios
        .post("/crearsugerencias/", {
          nombre: nombreS,
          correo: correo,
          sugerencia: sugerencia,
        })
        .then(function (response) {
          setSugerencia("");
          setCheck(false);
          handleCloseSugerencia();
          handleOpenAlertSugerencia();
        })
        .catch(function (error) {});

      console.log(data1);
    } catch (error) {}
  };

  const convos = useMemo(
    () => [
      {
        accessorKey: "nombreEquipo",
        header: "Equipo(s)",
        size: 1,
      },
      {
        accessorKey: "nombre",
        header: "Poyecto",
        size: 10,
      },
      {
        accessorKey: "actividad1",
        header: "Tipo de actividad",
        size: 1,
      },
      {
        accessorKey: "fecha",
        header: "Fecha",
        size: 1,
      },
      {
        accessorKey: "estadoConvo",
        header: "Estado",
        size: 1,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 1,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 1,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 1,
      },
    ],
    []
  );

  const myConvos = useMemo(
    () => [
      {
        accessorKey: "estados",
        header: "Estatus",
        size: 1,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === 1 ? (
              <Box
                sx={{
                  backgroundColor: "green",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                {" "}
                Aceptada{" "}
              </Box>
            ) : cell.getValue() === 2 ? (
              <Box
                sx={{
                  backgroundColor: "black",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                {" "}
                Rechazada{" "}
              </Box>
            ) : cell.getValue() === 3 ? (
              <Box
                sx={{
                  backgroundColor: "darkorange",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "14ch",
                  p: "0.25rem",
                }}
              >
                {" "}
                En Espera{" "}
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "firebrick",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                {" "}
                Cancelada{" "}
              </Box>
            )}
          </Box>
        ),
      },
      {
        accessorKey: "nombreEquipo",
        header: "Equipo(s)",
        size: 1,
      },
      {
        accessorKey: "nombre",
        header: "Poyecto",
        size: 10,
      },
      {
        accessorKey: "actividad",
        header: "Tipo de actividad",
        size: 1,
      },
      {
        accessorKey: "fecha",
        header: "Fecha",
        size: 1,
      },
      {
        accessorKey: "estadoConvo",
        header: "Estado",
        size: 1,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 1,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 1,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 1,
      },
  
    ],
    []
  );

  const aceptadas = async (id) => {
    try {
      const { data1 } = await axios
        .get("/aceptada/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setAceptada(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const canceladas = async (id) => {
    try {
      const { data1 } = await axios
        .get("/canceladas/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setCancelada(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const esperas = async (id) => {
    try {
      const { data1 } = await axios
        .get("/espera/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setEspera(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const rechazadas = async (id) => {
    try {
      const { data1 } = await axios
        .get("/rechazadas/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setRechazada(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const propia = async () => {
    try {
      const { data1 } = await axios
        .get("/propias/", {
          params: {
            email: items.usuario,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setPropias(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getEquipos = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos")
        .then(function (response) {
          // setAlerta({})

          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  if (ejecutar) {
    getEquipos();
    convocatorias();
    convocatoriasFinalizada();
    propia();
  }
  useEffect(() => {
    setDatos({
      aceptada: aceptada,
      cancelada: cancelada,
      espera: espera,
      rechazada: rechazada,
      propias: propias,
    });
    setTabla(true);
  }, [aceptada, espera, , rechazada, cancelada]);
  const nuv = (data) => {
    setData(data);
  };

  if (!data || !propias) {
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  } else {
    //console.log(propias[0].id)
    console.log(equipos);
    console.log(data);

    var datN = data;
    var nomE = "";
    //console.log(datN[0].equipos)
    for (let k = 0; k < datN.length; k++) {
      datN[k].nombreEquipo = "";
      const obj = JSON.parse(datN[k].equipos);
      for (let f = 0; f < obj.length; f++) {
        for (let j = 0; j < equipos.length; j++) {
          if (obj[f] == equipos[j].id) {
            nomE = equipos[j].nombre;
          }
        }
        datN[k].nombreEquipo = datN[k].nombreEquipo + ", " + nomE;
      }
      datN[k].nombreEquipo = datN[k].nombreEquipo.slice(1);
    }
    // for (let i = 0; i<datN.length; i++) {

    //   for (let l = 0; l<propias.length; l++) {
    //   if (propias[l].id != datN[i].id) {
    //     nuevas.push(datN[i])
    //   }
    // }
    // }
    var datP = propias;
    var nomEP = "";
    //console.log(datN[0].equipos)
    for (let k = 0; k < datP.length; k++) {
      datP[k].nombreEquipo = "";
       datP[k].actividad1=JSON.parse(datP[k].actividad);
      const obj1 = JSON.parse(datP[k].equipos);
      for (let f = 0; f < obj1.length; f++) {
        for (let j = 0; j < equipos.length; j++) {
          if (obj1[f] == equipos[j].id) {
            nomEP = equipos[j].nombre;
          }
        }
        datP[k].nombreEquipo = datP[k].nombreEquipo + ", " + nomEP;
      }
      datP[k].nombreEquipo = datP[k].nombreEquipo.slice(1);
    }
    //////
    for (let k = 0; k < datP.length; k++) {
      let c=datP[k].fecha+" "+datP[k].horaEncuentro;
     
      const a = dayjs(c);
      const b = a.subtract(42, "h").format("YYYY-MM-DD HH:mm");

      let rActivo = dayjs(hoy).isAfter(dayjs(b));
    
      datP[k].fechalimite = b;
      datP[k].refrigerioActivo= rActivo;
    }
    //////
    var datCF = dataF;
    var nomEF = "";
    //console.log(datN[0].equipos)
    for (let k = 0; k < datCF.length; k++) {
      datCF[k].nombreEquipo = "";
      const obj2 = JSON.parse(datCF[k].equipos);
      for (let f = 0; f < obj2.length; f++) {
        for (let j = 0; j < equipos.length; j++) {
          if (obj2[f] == equipos[j].id) {
            nomEF = equipos[j].nombre;
          }
        }
        datCF[k].nombreEquipo = datCF[k].nombreEquipo + ", " + nomEF;
      }
      datCF[k].nombreEquipo = datCF[k].nombreEquipo.slice(1);
    }
    /////
    for (let l = 0; l < propias.length; l++) {
      var greaterTen2 = datN.filter((dat) => dat.id != propias[l].id);
      datN = greaterTen2;
    }
    for (let k = 0; k < datN.length; k++) {
      let c=datN[k].fecha+" "+datN[k].horaEncuentro;
      datN[k].actividad1=datN[k].actividad;
      const a = dayjs(c);
      const b = a.subtract(42, "h").format("YYYY-MM-DD HH:mm");

      let rActivo = dayjs(hoy).isAfter(dayjs(b));
    
      datN[k].fechalimite = b;
      datN[k].refrigerio= rActivo;
    }
    console.log(datN, "estas son las activas");
  }
console.log(datP,"propias")
  return (
    <Box m="1.5rem 2.5rem">
      <Collapse in={alertRefri}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertRefri(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            right: 12,
            zIndex: 999,
            borderRadius: 2,
            boxShadow: 4,
          }}
        >
          ¡Tu refrigerio fue confirmado!
        </Alert>
      </Collapse>
      <Collapse in={rejectRefri}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setRejectRefri(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            right: 12,
            zIndex: 999,
            borderRadius: 2,
            boxShadow: 4,
          }}
        >
          ¡Tu refrigerio fue cancelado!
        </Alert>
      </Collapse>

      <Box role="presentation" onClick={handleClickBreadCrumbs} sx={{ mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href={"/inicio"}
            onClick={(event) => handleLinkHome(event, "home")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inicio
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <VolunteerActivismIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Voluntariado
          </Typography>
        </Breadcrumbs>
        <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
          {" "}
          Voluntariado{" "}
        </Typography>
        <Box
          mt="10px"
          display="grid"
          gridTemplateColumns="repeat(8, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            p="1.5rem"
            borderRadius="1.5rem"
            mt="10px"
            height="fit-content"
          >
            <Grid container>
              {/*<Grid item xs={12} mt={2}>
                <Card
                  className="text-center zoom"
                  sx={{
                    borderRadius: 6,
                    boxShadow: 8,
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        <Avatar
                          sx={{
                            width: 80,
                            height: 80,
                            bgcolor: "teal",
                            mt: 2,
                            mb: 2,
                            ml: 1,
                          }}
                        >
                          <LeaderboardRoundedIcon
                            sx={{ fontSize: 50, color: "white" }}
                          />
                        </Avatar>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold", mt: 4 }}
                          gutterBottom
                        >
                          Mis Estadísticas
                        </Typography>
                        <Button
                          variant="contained"
                          size="lg"
                          sx={{
                            bgcolor: "teal",
                            color: "white",
                            borderRadius: 2,
                            mt: 2,
                          }}
                          fullWidth
                          disabled
                        >
                          Ver
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>*/}
              {/*<Grid item xs={12} mt={2}>
                <Card
                  className="text-center zoom"
                  sx={{
                    borderRadius: 6,
                    boxShadow: 8,
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        <Avatar
                          sx={{
                            width: 80,
                            height: 80,
                            bgcolor: "teal",
                            mt: 2,
                            mb: 2,
                            ml: 1,
                          }}
                        >
                          <EmojiEventsIcon
                            sx={{ fontSize: 50, color: "white" }}
                          />
                        </Avatar>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold", mt: 4 }}
                          gutterBottom
                        >
                          Mis Logros
                        </Typography>
                        <Button
                          variant="contained"
                          size="lg"
                          sx={{
                            bgcolor: "teal",
                            color: "white",
                            borderRadius: 2,
                            mt: 2,
                          }}
                          fullWidth
                          disabled
                        >
                          Ver
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>*/}
              <Grid item xs={12} mt={2}>
                <Card
                  className="text-center zoom"
                  sx={{
                    borderRadius: 6,
                    boxShadow: 8,
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        <Avatar
                          sx={{
                            width: 80,
                            height: 80,
                            bgcolor: "teal",
                            mt: 2,
                            mb: 2,
                            ml: 1,
                          }}
                        >
                          <PostAddIcon sx={{ fontSize: 50, color: "white" }} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold", mt: 4 }}
                          gutterBottom
                        >
                          Sugerencias
                        </Typography>
                        <Button
                          variant="contained"
                          size="lg"
                          sx={{
                            bgcolor: "teal",
                            color: "white",
                            borderRadius: 2,
                            mt: 2,
                          }}
                          onClick={handleSugerencia}
                          fullWidth
                        >
                          Ver
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={theme.palette.background.alt}
            p="1.5rem"
            borderRadius="1.5rem"
            mt="40px"
            height="fit-content"
            sx={{ boxShadow: 4 }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeTabs}
                  variant={isSmallScreen ? "scrollable" : "fullWidth"}
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="basic tabs example"
                >
                  <Tab
                    icon={<CalendarMonthIcon />}
                    iconPosition="start"
                    label="Convocatorias Activas"
                    {...a11yProps(0)}
                    wrapped
                  />
                  <Tab
                    icon={<EventAvailableIcon />}
                    iconPosition="start"
                    label="Mis Convocatorias"
                    {...a11yProps(1)}
                    wrapped
                  />
                  <Tab
                    icon={<EventBusyIcon />}
                    iconPosition="start"
                    label="Convocatorias Finalizadas"
                    {...a11yProps(2)}
                    wrapped
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={valueTabs} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" mt={1} sx={{ fontWeight: "bold" }}>
                      {" "}
                      Convocatorias Activas{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        size="small"
                        onClick={(event) =>
                          handleLinkClick(event, "convocatorias")
                        }
                        style={{
                          display:
                            items.rol === "3"
                              ? "none"
                              : items.rol === "4"
                              ? "none"
                              : items.rol === "5"
                              ? "none"
                              : "block",
                        }}
                      >
                        <MoreTimeIcon /> Nueva Convocatoria
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <MaterialReactTable
                  columns={convos}
                  data={datN}
                  localization={MRT_Localization_ES}
                  enableRowActions
                  positionExpandColumn="first"
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      onClick={(event) =>
                        handleLinkEditar(event, "editar", row)
                      }
                      style={{
                        display:
                          items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : items.rol === "9"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <EditIcon sx={{ mr: 1 }} /> Editar Convocatoria{" "}
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => {
                        handleOpen(row);
                        closeMenu();
                      }}
                      style={{
                        display:
                          items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : items.rol === "9"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <AssignmentTurnedInIcon sx={{ mr: 1 }} /> Completar
                      Convocatoria{" "}
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => {
                        handleOpenModal(row);
                        closeMenu();
                      }}
                      style={{
                        display:
                            items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : items.rol === "9"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <SummarizeRoundedIcon sx={{ mr: 1 }} />
                      Listado de Voluntarios{" "}
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => handleOpenRefrigerios(row,"crear")}
                      style={{
                        display:
                          row.original.ident && row.original.tipo == 1 //&& !row.original.refrigerio
                            ? "block"
                            : row.original.extra == "Si" //&& row.original.ident
                            ? "block"
                            : row.original.ident &&
                              row.original.tipo == 2 &&
                              row.original.identE //&& !row.original.refrigerio
                            ? "block"
                            : row.original.ident &&
                              row.original.tipo == 3 &&
                              row.original.identE //&& !row.original.refrigerio
                            ? "block"
                            : "none",
                      }}
                    >
                      <CheckIcon sx={{ mr: 1 }} /> Confirmar Asistencia
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => handleRechazar(row)}
                      style={{
                        display:
                          row.original.ident && row.original.tipo == 1
                            ? "block"
                            : row.original.ident &&
                              row.original.tipo == 2 &&
                              row.original.identE
                            ? "block"
                            : row.original.ident &&
                              row.original.tipo == 3 &&
                              row.original.identE
                            ? "block"
                            : "none",
                      }}
                    >
                      <BlockIcon sx={{ mr: 1 }} /> Rechazar Convocatoria
                    </MenuItem>,
                    <MenuItem
                      sx={{ color: "#FF6347" }}
                      
                      onClick={(event) =>{
                        handleEliminar(row.original) 
                        closeMenu()}}
                      style={{
                        display: items.rol === "1"
                          ? "Block"
                          : "none",
                      }}
                    >
                      <DeleteIcon sx={{ mr: 1 }} />{" "}
                      <b>Eliminar Convocatoria</b>
                    </MenuItem>,
                    <MenuItem
                    onClick={(event) => closeMenu()}
                      style={{
                        display: row.original.ident
                          ? "none"
                          : items.rol === "1"
                          ? "none"
                          : items.rol === "2"
                          ? "none"
                          : items.rol === "8"
                          ? "none"
                          : items.rol === "9"
                          ? "none"
                          : "block",
                      }}
                    >
                      {" "}
                      <ErrorOutlineOutlinedIcon sx={{ mr: 1 }} /> Sin Opciones
                      Disponibles{" "}
                    </MenuItem>,
                  ]}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableHeadCellProps={{
                    align: "center",
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: theme.palette.background.light,
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    align: "center",
                  }}
                  muiTablePaperProps={{
                    elevation: 0, //change the mui box shadow
                    //customize paper styles
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    style: {
                      backgroundColor:
                        row.original.ident && row.original.tipo == 1
                          ? "DarkSeaGreen"
                          : row.original.ident &&
                            row.original.tipo == 2 &&
                            row.original.identE
                          ? "DarkSeaGreen"
                          : row.original.ident &&
                            row.original.tipo == 3 &&
                            row.original.identE
                          ? "DarkSeaGreen"
                          : theme.palette.background.light,
                    },
                  })}
                  initialState={{
                    pagination: {
                      pageSize: 20,
                      pageIndex: 0,
                    },
                    sorting: [
                      {
                        id: "fecha",
                        desc: true,
                      },
                    ],
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                    showFirstButton: false,
                    showLastButton: false,
                    SelectProps: {
                      native: true,
                    },
                    labelRowsPerPage: "Número de filas visibles",
                  }}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <b>Punto de Encuentro: </b>{" "}
                        {row.original.puntoEncuentro}
                      </Typography>
                      <Typography>
                        <b>Hora de encuentro: </b> {row.original.horaEncuentro}
                      </Typography>
                      <Typography>
                        <b>Hora de salida: </b> {row.original.horaSalida}
                      </Typography>
                      <Typography>
                        <b>Hora Estimada de retorno: </b>{" "}
                        {row.original.horaRetorno}
                      </Typography>
                    </Box>
                  )}
                />
              </CustomTabPanel>
              <CustomTabPanel value={valueTabs} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {" "}
                      Mis Convocatorias{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <MaterialReactTable
                  columns={myConvos}
                  data={datP}
                  localization={MRT_Localization_ES}
                  enableRowActions
                  positionExpandColumn="first"
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      onClick={(event) =>
                        handleLinkEditar(event, "editar", row)
                      }
                      style={{
                        display:
                          items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : items.rol === "9"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <EditIcon sx={{ mr: 1 }} /> Editar Convocatoria{" "}
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => {
                        handleOpen(row);
                        closeMenu();
                      }}
                      style={{
                        display:
                          items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : items.rol === "9"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <AssignmentTurnedInIcon sx={{ mr: 1 }} /> Completar
                      Convocatoria{" "}
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => {
                        handleOpenModal(row);
                        closeMenu();
                      }}
                      style={{
                        display:
                          items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : items.rol === "9"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <SummarizeRoundedIcon sx={{ mr: 1 }} />
                      Listado de Voluntarios{" "}
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => {
                        handleOpenRefrigerios(row,"actualizar");
                        closeMenu();
                      }}
                      style={{
                        display:
                        !row.original.refrigerioActivo
                            ? "block"
                            : "none"
                      }}
                    >
                      <FastfoodIcon sx={{ mr: 1 }} /> Refrigerio
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => {
                        handleOpenMF(row);
                        closeMenu();
                      }}
                      style={{
                        display:
                          row.original.estados == 1
                            ? "block"
                            : row.original.estados == 3
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <CloseIcon sx={{ mr: 1 }} /> Cancelar Asistencia{" "}
                    </MenuItem>,
                    <MenuItem
                      sx={{ color: "#FF6347" }}
                      onClick={(event) => closeMenu()}
                      style={{
                        display: row.original.ident
                          ? "Block"
                          : items.rol === "1"
                          ? "Block"
                          : "none",
                      }}
                    >
                      <DeleteIcon sx={{ mr: 1 }} />{" "}
                      <b>Eliminar Convocatoria</b>
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => handleCloseDropDown()}
                      style={{
                        display:
                          items.rol === "1"
                            ? "none"
                            : items.rol === "2"
                            ? "none"
                            : items.rol === "8"
                            ? "none"
                            : row.original.estados == 4
                            ? "block"
                            : row.original.estados == 2
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <ErrorOutlineOutlinedIcon sx={{ mr: 1 }} /> Sin Opciones
                      Disponibles{" "}
                    </MenuItem>,
                  ]}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableHeadCellProps={{
                    align: "center",
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: theme.palette.background.light,
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    align: "center",
                  }}
                  muiTablePaperProps={{
                    elevation: 0, //change the mui box shadow
                    //customize paper styles
                  }}
                  initialState={{
                    pagination: {
                      pageSize: 20,
                      pageIndex: 0,
                    },
                    sorting: [
                      {
                        id: "fecha",
                        desc: true,
                      },
                    ],
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                    showFirstButton: false,
                    showLastButton: false,
                    SelectProps: {
                      native: true,
                    },
                    labelRowsPerPage: "Número de filas visibles",
                  }}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <b>Punto de Encuentro: </b>{" "}
                        {row.original.puntoEncuentro}
                      </Typography>
                      <Typography>
                        <b>Hora de encuentro: </b> {row.original.horaEncuentro}
                      </Typography>
                      <Typography>
                        <b>Hora de salida: </b> {row.original.horaSalida}
                      </Typography>
                      <Typography>
                        <b>Hora Estimada de retorno: </b>{" "}
                        {row.original.horaRetorno}
                      </Typography>
                    </Box>
                  )}
                />
              </CustomTabPanel>
              <CustomTabPanel value={valueTabs} index={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" mt={1} sx={{ fontWeight: "bold" }}>
                      {" "}
                      Convocatorias Finalizadas{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    ></Box>
                  </Grid>
                </Grid>
                <MaterialReactTable
                  columns={convos}
                  data={datCF}
                  localization={MRT_Localization_ES}
                  enableRowActions
                  positionExpandColumn="first"
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      onClick={(event) => {
                        handleOpenModal(row);
                        closeMenu();
                      }}
                      style={{
                        display:
                          items.rol === "1"
                            ? "block"
                            : items.rol === "2"
                            ? "block"
                            : items.rol === "8"
                            ? "block"
                            : "none",
                      }}
                    >
                      {" "}
                      <SummarizeRoundedIcon sx={{ mr: 1 }} />
                      Listado de Voluntarios{" "}
                    </MenuItem>,
                  ]}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableHeadCellProps={{
                    align: "center",
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: theme.palette.background.light,
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    align: "center",
                  }}
                  muiTablePaperProps={{
                    elevation: 0, //change the mui box shadow
                    //customize paper styles
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    style: {
                      backgroundColor: row.original.ident
                        ? "DarkSeaGreen"
                        : theme.palette.background.light,
                    },
                  })}
                  initialState={{
                    pagination: {
                      pageSize: 20,
                      pageIndex: 0,
                    },
                    sorting: [
                      {
                        id: "fecha",
                        desc: true,
                      },
                    ],
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                    showFirstButton: false,
                    showLastButton: false,
                    SelectProps: {
                      native: true,
                    },
                    labelRowsPerPage: "Número de filas visibles",
                  }}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <b>Punto de Encuentro: </b>{" "}
                        {row.original.puntoEncuentro}
                      </Typography>
                      <Typography>
                        <b>Hora de encuentro: </b> {row.original.horaEncuentro}
                      </Typography>
                      <Typography>
                        <b>Hora de salida: </b> {row.original.horaSalida}
                      </Typography>
                      <Typography>
                        <b>Hora Estimada de retorno: </b>{" "}
                        {row.original.horaRetorno}
                      </Typography>
                      <Typography>
                        <b>Comentario Final: </b>{" "}
                        {row.original.comentarios_finales}
                      </Typography>
                    </Box>
                  )}
                />
              </CustomTabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
      >
        <Box sx={styleVol}>
          {tabla ? <VoluntList datos={datos} /> : ""}
          <Divider sx={{ mt: 2 }} />
          <Grid container sx={{ mt: 2 }} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseModal}
              >
                {" "}
                Salir{" "}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
      >
        <Box sx={style}>
          <Typography variant="h4" mb={1}>
            {" "}
            Completar: {datosCompletar.actividad}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h6" className="text-center" sx={{ mb: 1.5 }}>
            {" "}
            Ingrese un comentario final{" "}
          </Typography>
          <TextField
            name="cFinal"
            fullWidth
            id="cFinal"
            label="Comentario Final"
            value={valueComentarioFinal}
            onChange={(event) => setComentarioFinal(event.target.value)}
          />
          <Divider sx={{ mt: 4 }} />
          <Grid container sx={{ mt: 2 }} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color="error" onClick={handleClose}>
                {" "}
                Cancelar{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={(event) => {
                  handleCompletar(datosCompletar);
                }}
                variant="contained"
                sx={{ bgcolor: "teal", color: "white" }}
                disabled={!valueComentarioFinal}
              >
                {" "}
                Aceptar{" "}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openMF}
        onClose={handleCloseMF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
      >
        <Box sx={style}>
          <Typography variant="h4" mb={1}>
            {" "}
            Cancelar Asistencia{" "}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h6" className="text-center" sx={{ mb: 1.5 }}>
            {" "}
            ¿Cuál es el motivo de la inasistencia?{" "}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="mFalta"
              value={mFalta}
              fullWidth
              id="mFalta"
              label="Motivo de Falta"
              onChange={handleFalta}
              required
            />
          </form>
          <Divider sx={{ mt: 4 }} />
          <Grid container sx={{ mt: 2 }} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color="error" onClick={handleCloseMF}>
                {" "}
                Cancelar{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ bgcolor: "teal", color: "white" }}
                onClick={(event) => cancelar(datosCancelar)}
              >
                {" "}
                Aceptar{" "}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openSugerencia}
        onClose={handleCloseSugerencia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
      >
        <Box sx={styleSug}>
          <Typography variant="h4" mb={1}>
            {" "}
            Sugerencias{" "}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography
            variant="h5"
            className="text-center"
            sx={{ mt: 1.5, mb: 1.5 }}
          >
            {" "}
            ¿Tienes alguna sugerencia?{" "}
          </Typography>
          <form onSubmit={handleSubmitSugerencias}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="anonimo"
                    id="anonimo"
                    checked={anonimo}
                    onChange={handleChangeCheck}
                  />
                }
                label="Anónimo"
              />
            </FormGroup>
            <TextField
              value={sugerencia}
              name="sugerencia"
              id="sugerencia"
              label="Sugerencias"
              multiline
              rows={4}
              fullWidth
              sx={{ mt: 2 }}
              onChange={handleFormSugerencia}
            />
            <Divider sx={{ mt: 4 }} />
            <Grid
              container
              sx={{ mt: 2 }}
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSugerencia}
                >
                  {" "}
                  Cancelar{" "}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "teal", color: "white" }}
                >
                  {" "}
                  Enviar{" "}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal
        open={openAlertConfirm}
        onClose={handleCloseAlertConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <TaskAltOutlinedIcon
              color="success"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            ¡Asistencia confirmada!
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertErrorCorfirm}
        onClose={handleCloseAlertErrorConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <ErrorOutlinedIcon
              color="error"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            Ya confirmaste tu asistencia para esta convocatoria
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertErrorReject}
        onClose={handleCloseAlertErrorReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <ErrorOutlinedIcon
              color="error"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            Ya rechazaste esta convocatoria anteriormente
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertCancel}
        onClose={handleCloseAlertCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <TaskAltOutlinedIcon
              color="success"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            ¡Asistencia cancelada!
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertReject}
        onClose={handleCloseAlertReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <TaskAltOutlinedIcon
              color="success"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            ¡Convocatoria Rechazada!
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertComplete}
        onClose={handleCloseAlertComplete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <TaskAltOutlinedIcon
              color="success"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            ¡Convocatoria Completada Exitosamente!
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertWaiting}
        onClose={handleCloseAlertWaiting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <ScheduleIcon
              sx={{
                fontWeight: "bold",
                mt: 3,
                fontSize: 80,
                color: "darkorange",
              }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            En espera...
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, mb: 3, fontSize: 18, fontWeight: "bold" }}
          >
            No hay cupos disponibles, serás agregado(a) a la lista de espera.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openAlertSugerencia}
        onClose={handleCloseAlertSugerencia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className="text-center">
            <TaskAltOutlinedIcon
              color="success"
              sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            className="text-center"
            sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
          >
            ¡Gracias por tu Sugerencia!
          </Typography>
        </Box>
      </Modal>

      <Modal open={openRefrigerios} onClose={refrigerios==="crear"? '': handleCloseRefrigerios}>
        <Box sx={styleRefri}>
          <Typography variant="h4" mb={1} sx={{ fontWeight: "bold" }}>
            <FastfoodIcon sx={{ fontSize: 35 }} /> Refrigerio
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h5" className="text-center">
            <b>¿Quieres recibir tu refrigerio para esta convocatoria?</b>
          </Typography>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <Grid container sx={{ mt: 2 }} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={(event) =>
                  handleRefrigerios(event, "No", datosGuardar)}
              >
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ bgcolor: "teal", color: "white" }}
                onClick={(event) =>
                  handleRefrigerios(event, "Si", datosGuardar)}
              >
                Si
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default Voluntariado;
